export default [
  {
    name: `actifs-excellence-absolue`,
    title: `Des actifs d'une excellence absolue`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/actifs_excellence.vtt`,
  },
  {
    name: `annee-domaine-clarins`,
    title: `Une année au Domaine Clarins`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/annee_au_domaine.vtt`,
  },
  {
    name: `ceuillette-au-sechage`,
    title: `De la cueillette au séchage`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/cueillette_au_sechage.vtt`,
  },
  {
    name: `cultures-100-bio`,
    title: `Des cultures 100 % biologiques`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/cultures_100_bio.vtt`,
  },
  {
    name: `debut-longue-aventure`,
    title: `La découverte du Domaine par Christian Courtin-Clarins`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/intro_courtin.vtt`,
  },
  {
    name: `engagement-preservation-alpes`,
    title: `Un nouvel engagement environnemental `,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/engagement_environnemental.vtt`,
  },
  {
    name: `engages-sauvegarde-alpes`,
    title: `Engagés pour la sauvegarde des Alpes`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/sauvegarde_alpes.vtt`,
  },
  {
    name: `equipe-petits-soins`,
    title: `Une équipe aux petits soins`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/equipe_devouee.vtt`,
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    title: `La gentiane jaune<br/>Gentiana lutea`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/gentiane_jaune.vtt`,
  },
  {
    name: `gypaete-barbu`,
    title: `Le gypaète barbu`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/gypaete_barbu.vtt`,
  },
  {
    name: `joubarbe-des-toits`,
    title: `La joubarbe des toits<br/>Sempervivum tectorum`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/joubarbe.vtt`,
  },
  {
    name: `laboratoire-ciel-ouvert`,
    title: `Un laboratoire à ciel ouvert`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/jardin_collection.vtt`,
  },
  {
    name: `lieu-culture-experimentation`,
    title: `Lieu de culture et d'expérimentation`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/milieu_culture.vtt`,
  },
  {
    name: `melisse`,
    title: `La mélisse`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/melisse.vtt`,
  },
  {
    name: `partenaires-locaux`,
    title: `Des partenaires locaux de confiance`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/partenaires_locaux.vtt`,
  },
  {
    name: `plantes-sauvages`,
    title: `Les plantes sauvages`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/plantes_sauvages.vtt`,
  },
  {
    name: `production-circuit-court`,
    title: `Notre production en circuit court`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/production_circuit_court.vtt`,
  },
  {
    name: `respect-sols`,
    title: `Le respect des sols`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/respect_sols.vtt`,
  },
  {
    name: `richesse-purete-alpes`,
    title: `La richesse et la pureté des Alpes`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/richesse_purete.vtt`,
  },
  {
    name: `saponaire-saponaria-officinalis`,
    title: `La saponaire<br/>Saponaria officinalis`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/saponaire.vtt`,
  },
  {
    name: `tracabilite-exemplaire`,
    title: `Une traçabilité exemplaire`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/tracabilite_exemplaire.vtt`,
  },
  {
    name: `sources-energie-propres`,
    title: `Des sources d'énergie propres`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/source_energie_propre.vtt`,
  },
  {
    name: `sourcing-responsable`,
    title: `Un sourcing responsable`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/sourcing_responsable.vtt`,
  },
  {
    name: `voilages-protecteurs`,
    title: `Des voilages protecteurs`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/subtitles/voilages_protecteurs.vtt`,
  },
];