export default {
  name: `fab-buttons-config`,
  csrFabButton: {
    cta: `TERMINER`,
    label: [`la Visite`, `Virtuelle`],
  },
  hubFabButton: {
    cta: `OUVRIR`,
    label: [`le plan`, `du Domaine`],
  },
  csrDialogFabButton: {
    cta: `DÉCOUVREZ`,
    label: [`Le Domaine`, `des Alpes`, ` en 360°`],
  },
};
