
export default [
  {
    url: `https://dctv-s3.reality-xr.com/fonts/GothamSSm-ExtraLight.otf`,
    name: `gotham`,
    weight: `200`,
  },
  {
    url: `https://dctv-s3.reality-xr.com/fonts/GothamSSm-Book.otf`,
    name: `gotham`,
    weight: `normal`,
  },
  {
    url: `https://dctv-s3.reality-xr.com/fonts/GothamSSm-Medium.otf`,
    name: `gotham`,
    weight: `bold`,
  },
];
