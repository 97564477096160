<template>
  <div class="csr-video-dialog" :class="{ visible }" @click.self="close">
    <div class="video-wrapper" @click.stop="noop">
      <video
      
      @click="close"
        :src="video.src"
        ref="video"
        crossorigin="anonymous"
        loop
        v-if="hasVideo"
      />
    </div>
   <!-- <clarins-fab-button
      :config="fabButtonConfig"
      :visible="true"
      align="right"
      @click="close"
    />
    <div class="csr-dialog" :class="{ visible: csrDialogVisible }" @click.stop="closeThroughDialog">
      <div class="csr-dialog-cta" v-html="dialog.cta" />
      <img class="csr-dialog-domain-image" :src="dialog.domain" />
      <div class="csr-dialog-button" v-html="dialog.buttonLabel" />
      <img class="csr-dialog-plant csr-dialog-plant-top" :src="dialog.plantTop" />
      <img class="csr-dialog-plant csr-dialog-plant-bottom" :src="dialog.plantBottom" />
    </div> -->
  </div>
</template>

<script>
// import ClarinsFabButton from '@/components/ClarinsFabButton';

export default {
  components: {
   // ClarinsFabButton,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    fabButtonConfig: {
      type: Object,
      required: true,
    },
    video: {
      type: Object,
    },
    dialog: {
      type: Object,
    },
  },

  data: () => ({
    instance: null,
    dialogInterval: -1,
    csrDialogVisible: false,
  }),

  computed: {
    hasVideo() {
      return this.video && this.video.src;
    },
  },

  methods: {
    noop() {},
    close() {
      this.$emit(`close`);
    },
    closeThroughDialog() {
      if (this.csrDialogVisible) {
        this.close();
      }
    },
    checkCurrentVideoTime() {
      let timingIndex = -1;
      let shouldDisplayDialog = false;
      const currentTime = this.$refs[`video`].currentTime;

      for (let i = 0; i < this.dialog.videoTimings.length; i++) {
        if (currentTime >= this.dialog.videoTimings[i] - 0.5
        && currentTime <= this.dialog.videoTimings[i] + 0.1) {
          shouldDisplayDialog = true;
          timingIndex = i;
          break;
        }
      }

      if (shouldDisplayDialog) {
        this.displayCsrDialog(timingIndex);
      }
    },
    displayCsrDialog(timingIndex) {
      clearInterval(this.dialogInterval);
      this.csrDialogVisible = true;
      setTimeout(() => {
        this.$refs[`video`].pause();
        this.$refs[`video`].currentTime = (timingIndex === 2 ? 0 : this.dialog.videoTimings[timingIndex] + 0.3);
      }, 500);
      setTimeout(() => {
        this.$refs[`video`].play();
        this.csrDialogVisible = false;
      }, 5500);
      setTimeout(() => {
        this.dialogInterval = setInterval(this.checkCurrentVideoTime, 100);
      }, 5800)
    }
  },

  watch: {
    visible(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs[`video`].currentTime = 0;
          this.$refs[`video`].play();
        }, 500);
        this.dialogInterval = setInterval(this.checkCurrentVideoTime, 100);
      } else {
        this.$refs[`video`].pause();
        setTimeout(() => {
          this.$refs[`video`].currentTime = 0;
        }, 400);
        clearInterval(this.dialogInterval);
        this.dialogInterval = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .video-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    height: 100%;
    width: 100%;

    video {
      height: 100vh;
      // width: 100%;
      // max-width: 600px;
    }
  }

  .csr-dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10vw;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .csr-dialog-cta {
      font-size: 5vw;
      font-weight: 900;
      color: #000;
      width: 90%;
    }

    .csr-dialog-domain-image {
      width: 100%;
      margin: 10vw 0;
    }

    .csr-dialog-button {
      font-size: 3vw;
      font-weight: 900;
      color: #fff;
      background-color: #d40f3f;
      padding: 0.5em 2.5em;
      border-radius: 0.25em;
    }

    .csr-dialog-plant {
      position: absolute;

      &.csr-dialog-plant-top {
        top: 0;
        right: 0;
        width: 30%;
        transform: translate(15%, -22%);
      }

      &.csr-dialog-plant-bottom {
        bottom: 0;
        left: 0;
        width: 20%;
        transform: translate(-5%, 0);
      }
    }
  }
}
</style>

<style lang="scss">
.art-auto-size {
  background-color: transparent !important;
}

.art-video-player {
  pointer-events: none;
  height: 100% !important;
  width: 100% !important;
  &, .art-video {
    background-color: transparent !important;
  }

  * {
    z-index: 0;
  }

  & > :not(video) {
    display: none !important;
  }
}
</style>
