<template>
  <div id="clarins-visit" :class="{ visible }">
    <clarins-scene
      :scene="currentScene"
      :visible="isSceneVisible"
      :hotspots-visible="!isOnboardingDialogOpened"
      :swipe-gif="visit.onboardingConfig.swipeGif"
      :is-csr-opened="isCsrVideoOpened"
      :hotspots-clickable="hotspotsClickable"
      :dialogs="dialogs"
      @scene-mounted="sceneMounted(currentScene)"
      @change-scene="changeScene"
      @open-dialog="openDialog"
      @user-interacted="userInteracted"
      @intro-finished="introFinished"
      @display-scene-cta="displaySceneCTA"
      @hide-scene-cta="hideSceneCTA"
      v-if="currentScene"
    />
    <clarins-fab-button
      :config="visit.fabButtons.csrFabButton"
      align="right"
      :visible="!isCsrVideoOpened && !isOnboardingDialogOpened && !introVideoVisible"
      @click="openCsrVideo"
      v-show="currentScene && !isCsrVideoOpened"
    />
    <clarins-fab-button
      :config="visit.fabButtons.hubFabButton"
      align="left"
      :enabled="isMapBtnActive"
      :has-pulse="mapButtonHasPulse"
      :visible="!isCsrVideoOpened && !isCurrentSceneHub && !isOnboardingDialogOpened && !introVideoVisible"
      @click="openHub"
      v-show="currentScene && !isCsrVideoOpened"
    />
    <clarins-dialog
      :app-audio-context="appAudioContext"
      :dialog="currentDialog"
      :visible="isDialogVisible"
      :flower-icon="visit.icons.flower"
      @close="closeDialog"
      @courtin-intro-finished="enableMapBtnPulse"
      vv-show="isDialogOpened"
    />
    <onboarding-dialog
      :visible="isOnboardingDialogOpened"
      :config="visit.onboardingConfig"
      @close="closeOnboardingDialog"
    />
    <div class="intro-cta" :class="{ visible: !isCsrVideoOpened && !isDialogOpened && !isOnboardingDialogOpened && !introVideoVisible && !isIntroFinished && isSceneVisible }">
      <img :src="visit.onboardingConfig.introMessage" alt="intro message" />
    </div>
    <div class="scene-cta" :class="{ visible: !isCsrVideoOpened && !isOnboardingDialogOpened && !introVideoVisible && isIntroFinished && isSceneCTAVisible && isSceneVisible && !isDialogVisible }">
      {{ visit.onboardingConfig.sceneCTA }}
    </div>
    <div class="intro-video-wrapper" :class="{ visible: introVideoVisible }">
      <video
        :src="visit.introVideo"
        ref="intro_video"
        crossorigin="anonymous"
        playsinline
        :controls="false"
        @ended="closeIntroVideo"
      >
      </video>
      <a class="intro-video-skip" :class="{ hidden: !skipIntroVideoVisible }" @click="closeIntroVideo">
        <img :src="arrowImg" alt="Skip video" />
      </a>
    </div>
    <csr-video-dialog
      :video="csrVideo"
      :dialog="csrDialog"
      :fab-button-config="visit.fabButtons.csrDialogFabButton"
      :visible="isCsrVideoOpened"
      @close="csrVideoClosed"
    />
  </div>
</template>

<script>
import ClarinsDialog from '@/components/ClarinsDialog';
import OnboardingDialog from '@/components/OnboardingDialog';
import ClarinsScene from '@/components/ClarinsScene';
import ClarinsFabButton from '@/components/ClarinsFabButton';
import CsrVideoDialog from '@/components/CsrVideoDialog';
import arrowImg from '@/assets/hotspots/arrow.png';

const IDLE_TIMOUT = 120000; // 2 minutes

export default {
  components: {
    ClarinsDialog,
    OnboardingDialog,
    ClarinsScene,
    ClarinsFabButton,
    CsrVideoDialog,
  },

  props: {
    appAudioContext: {
      type: AudioContext,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    visit: {
      type: Object,
      required: true,
    },
    dialogs: {
      type: Array,
      default: () => [],
    },
    scenes: {
      type: Array,
      default: () => [],
    },
    csrVideo: {
      type: Object,
      default: () => null,
    },
    csrDialog: {
      type: Object,
      default: () => null,
    },
  },

  data: () => ({
    arrowImg: arrowImg,
    currentDialog: null,
    isDialogOpened: false,
    isDialogVisible: false,
    firstSceneName: `entree-domaine`,
    isChangingScene: false,
    currentSceneName: null,
    isCsrVideosDialogOpened: false,
    isCsrVideoOpened: false,
    idleTimeout: null,
    isSceneVisible: false,
    isOnboardingDialogOpened: false,
    isIntroFinished: false,
    isSceneCTAVisible: false,
    mapButtonHasPulse: false,
    introVideoVisible: false,
    skipIntroVideoVisible: false,
    hotspotsClickable: false,
    isMapBtnActive: false,
  }),

  computed: {
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }

      return currentScene || null;
    },
    isCurrentSceneHub() {
      return this.currentScene && this.currentScene.name === `hub`;
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    introFinished() {
      this.isIntroFinished = true;
    },
    displaySceneCTA() {
      this.isSceneCTAVisible = true;
    },
    hideSceneCTA() {
      this.isSceneCTAVisible = false;
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    visitIsIdle() {
      if (process.env.VUE_APP_DISABLE_CSR_VIDEOS === `0`) {
        this.openCsrVideo();
      }
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.webkitTransform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    userInteracted() {
      this.resetZoom();
      clearTimeout(this.idleTimeout);
      this.idleTimeout = setTimeout(() => this.visitIsIdle(), IDLE_TIMOUT);
    },
    closeOnboardingDialog() {
      this.isOnboardingDialogOpened = false;
      this.openDialog(`debut-longue-aventure`);
      this.userInteracted();
    },
    async changeScene(sceneName) {
      if (this.isChangingScene) { return; }
      if (sceneName === this.currentSceneName) {
        this.isSceneVisible = false;
        await this.wait(140);
        this.currentSceneName = null;
      }
      this.userInteracted();
      this.isChangingScene = true;
      this.isSceneVisible = false;
      this.isDialogOpened = false;

      await this.wait(140);
      this.currentSceneName = sceneName;
    },
    async sceneMounted(scene) {
      this.isChangingScene = false;
      this.$emit(`play-sound`, scene.sound || null);
      await this.wait(75);
      this.isSceneVisible = true;
    },
    openHub() {
      this.changeScene(`hub`);
      setTimeout(() => {
        this.mapButtonHasPulse = false;
      }, 500)
    },
    async openDialog(dialogName) {
      this.userInteracted();
      if (dialogName === `debut-longue-aventure`) {
        this.$root.$emit(`courtin-popup-opened`);
      }
      const dialog = this.getDialogByName(dialogName);
      this.currentDialog = dialog;
      this.isDialogOpened = true;
      await this.wait();
      this.isDialogVisible = true;
      this.$emit(`dialog-opened`, (this.currentSceneName === `chalet` ? 0 : 0.1), `dialog ${dialogName} in scene ${this.currentSceneName}`);
      if (this.currentDialog.audio) {
        setTimeout(() => {
          this.$emit(`play-sound`, this.currentDialog.audio);
        }, 500);
      }
    },
    async closeDialog() {
      this.isDialogVisible = false;
      if (this.currentDialog.audio) {
        this.$emit(`play-sound`, this.currentScene.sound || null);
      }
      this.$emit(`dialog-closed`, 1,  `dialog ${this.currentDialog.name} in scene ${this.currentSceneName}`);
      this.$root.$emit(`dialog-closed`);
      await this.wait(500);
      this.isDialogOpened = false;
    },
    openCsrVideo() {
      if (this.isCsrVideoOpened) { return; }
      this.isSceneVisible = false;
      this.isCsrVideoOpened = true;
      this.isMapBtnActive = false;
      this.hotspotsClickable = false;
      this.isDialogVisible = false;
      this.$emit(`video-started`);
    },
    csrVideoClosed() {
      this.$emit(`video-ended`);
      this.changeScene(this.firstSceneName);
      this.isCsrVideoOpened = false;
      this.$emit(`dialog-opened`, 0,  `csrVideoClosed`);
      this.introVideoVisible = true;
      this.$refs[`intro_video`].play();
      this.isIntroFinished = false;
    },
    async closeIntroVideo() {
      this.$emit(`dialog-opened`, 1, `closeIntroVideo`);
      this.isOnboardingDialogOpened = true;
      this.introVideoVisible = false;
      await this.wait(500);
      this.$refs[`intro_video`].pause();
      this.$refs[`intro_video`].currentTime = 0;
    },
    enableMapBtnPulse() {
      this.isMapBtnActive = true;
      this.mapButtonHasPulse = true;
      this.hotspotsClickable = true;
      // setTimeout(() => {
      //   this.mapButtonHasPulse = false;
      // }, 12000)
    }
  },

  mounted() {
    setTimeout(() => {
      this.isCsrVideoOpened = true;
    });
  },

  watch: {
    introVideoVisible(visible) {
      setTimeout(() => {
        this.skipIntroVideoVisible = visible;
      }, 1000);
    },
    isCsrVideoOpened(isCsrVideoOpened) {
      if (isCsrVideoOpened) {
        this.$emit(`dialog-opened`, 0, 'watcher CSRVideoOpened');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clarins-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.intro-cta {
  width: 100%;
  position: fixed;
  top: 3%;
  left: 0;
  right: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;

  img {
    width: 80%;
  }

  &.visible {
    opacity: 1;
  }
}

.scene-cta {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  color: #ffffff;
  font-size: 5vw;
  font-weight: 700;
  padding: 2em 0;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.6));

  &.visible {
    opacity: 1;
  }
}

.intro-video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  video {
    width: 100%;
  }

  .intro-video-skip {
    position: absolute;
    bottom: 5vw;
    right: 6vw;
    width: 8%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    img {
      width: 100%;
    }
  }
}
</style>
