export default {
  name: `onboarding-config`,
  introMessage: `https://dctv-s3.reality-xr.com/Wording_intro_360.png`,
  title: `Bienvenue dans le Domaine Clarins !`,
  subtitle: `Situé au cœur des Alpes françaises, le Domaine est le lieu idéal pour cultiver et récolter nos propres plantes, sans aucune pollution de l'air ni du sol. `,
  sceneCTA: `Poursuivez votre visite vers...`,
  instructions: [
    {
      name: `arrow`,
      legend: `Utilisez les flèches de déplacement pour explorer`,
    },
    {
      name: `hotspot`,
      legend: `Cliquez sur les points d'intérêt pour en apprendre d'avantage`,
    },
    {
      name: `map`,
      legend: `Sélectionnez la carte pour obtenir une vue d'ensemble`,
    },
    {
      name: `headset`,
      legend: `Mettez le casque audio pour une expérience plus immersive.`,
    },
  ],
  startCTA: `Commencer la visite`,
};
