export default {
  name: `onboarding-config`,
  swipeGif: `https://dctv-s3.reality-xr.com/swipe.gif`,
  instructions: [
    {
      name: `arrow`,
      icon: `https://dctv-s3.reality-xr.com/loading/arrow.png`,
    },
    {
      name: `hotspot`,
      icon: `https://dctv-s3.reality-xr.com/loading/hotspot.png`,
    },
    {
      name: `map`,
      icon: `https://dctv-s3.reality-xr.com/loading/map.png`,
    },
    {
      name: `headset`,
      icon: `https://dctv-s3.reality-xr.com/loading/headset.png`,
    },
  ],
};
