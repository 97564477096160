<template>
  <div class="loading-screen" :class="{ visible }">
    <div class="logo-wrapper">
      <img :src="config.logo" alt="Logo-Clarins"/>
    </div>
    <div class="welcome">
      {{ config.catchPhrase }}
    </div>
    <div class="preloading-area">
      <div v-if="isPreloading">
        <loading-progress
          indeterminate
          shape="line"
          size="600"
          width="600"
          height="20"
          fill-duration="20"
        />
        <div class="preloading-message">
          {{ config.loadingMessage }}
        </div>
      </div>
      <div class="start-button-wrapper" v-else>
        <a class="start-button" @click="close">
          {{ config.startCTA }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isPreloading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('https://dctv-s3.reality-xr.com/loading-background.png');
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &, * {
    color: #FFF;
  }
}

.logo-wrapper {
  // padding-top: 50px;
  padding-top: 2.5vh;
  img {
    width: 80%;
    // max-width: 280px;
    max-width: 25vw;
  }
}

.welcome {
  // padding: 80px 20px 0px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // left: 60px;
  // right: 60px;
  text-align: center;
  // margin-top: -20px;
  text-transform: uppercase;
  font-weight: 500;
  // font-size: 55px;
  font-size: 5vw;
}

.preloading-area {
  position: absolute;
  right: 0;
  bottom: 2.2vh;
  // bottom: 40px;
  left: 0;
}

.preloading-message {
  padding-top: 15px;
  font-size: 30px;
}

.start-button-wrapper {
  display: inline-block;
}

.start-button {
  padding: 4vw 8vw;
  // padding-left: 30px;
  // padding-right: 30px;
  // height: 50px;
  font-weight: 700;
  color: #FFF;
  background-color: #AB1822;
  border-radius: 2vw;
  font-size: 4vw;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">

.vue-progress-path .progress {
  stroke: #FFF !important;
  stroke-width: 18px !important;
}

.vue-progress-path .background {
  stroke: #AB1822 !important;
  stroke-width: 18px !important;
}
</style>
