
export default [
  {
    name: `entree-domaine`,
    title: `Entrée du Domaine`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `la gentiane et la saponaire`,
  },
  {
    name: `chalet`,
    title: `Le châlet`,
  },
  {
    name: `haut-domaine`,
    title: `Le haut du Domaine`,
  },
  {
    name: `jardin-collection`,
    title: `Le jardin collection`,
  },
  {
    name: `parcelle-cheval`,
    title: `La parcelle du cheval`,
  },
  {
    name: `plantations`,
    title: `Les plantations`,
  },
  {
    name: `partenaires`,
    title: `Nos partenaires`,
  },
];
