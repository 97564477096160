var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog",class:{
    visible: _vm.visible,
    bordered: _vm.hasVideo,
  },on:{"click":_vm.close}},[(_vm.dialog !== null)?_c('div',{staticClass:"dialog-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"dialog-video-wrapper",class:{ visible: _vm.isVideoVisible }},[_c('div',{staticClass:"dialog-title",domProps:{"innerHTML":_vm._s(_vm.dialog.title)}}),(_vm.hasVideo)?_c('div',{staticClass:"solo-video-wrapper"},[_c('video',{ref:"dialogVideo",attrs:{"src":_vm.video,"controls":false,"crossorigin":"anonymous","playsinline":"","webkit-playsinline":""},on:{"ended":_vm.videoEnded}},[_c('track',{attrs:{"kind":"metadata","src":_vm.dialog.subtitles,"default":""}})])]):_vm._e()]),(_vm.dialog.hasProducts)?_c('div',{staticClass:"dialog-products-wrapper",class:{ visible: _vm.dialog.hasProducts && !_vm.isVideoVisible }},[_c('div',{staticClass:"products-title",domProps:{"innerHTML":_vm._s(_vm.products.title)}}),_c('div',{staticClass:"flower-underline"},[_c('div',{staticClass:"line"}),_c('img',{attrs:{"src":_vm.flowerIcon,"alt":"flower-icon"}})]),_c('div',{staticClass:"products-wrapper",class:{
          podium: _vm.products.products.length === 3,
          table: _vm.products.products.length === 4,
        }},[_vm._l((_vm.products.products),function(p,idx){return [_c('div',{key:((_vm.dialog.name) + "-" + (p.name)),staticClass:"dialog-product",class:{
              upper: idx === 1 && _vm.products.products.length === 3,
            }},[_c('img',{staticClass:"product-image",attrs:{"src":p.image,"alt":p.label}}),_c('div',{staticClass:"product-label"},[_vm._v(_vm._s(p.label))]),_c('div',{staticClass:"product-desc"},[_vm._v(_vm._s(p.desc))])])]}),(_vm.products.products_extra)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.products.products_extra)}}):_vm._e()],2)]):_vm._e(),_c('a',{staticClass:"close-button",on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v("×")])]):_vm._e(),_c('div',{staticClass:"sound-visualizer",class:{ visible: _vm.isVideoVisible }},_vm._l((16),function(i){return _c('div',{key:("sound-dot-" + i),staticClass:"sound-dot",class:("sound-dot-" + i)})}),0),(_vm.dialog !== null && _vm.dialog.subtitles)?_c('div',{staticClass:"caption-wrapper",class:{ visible: _vm.isVideoVisible }},[_c('div',{staticClass:"caption-container",class:{
        'captions-visible':
          _vm.areCaptionsVisible && _vm.currentSubtitleContainer === 0,
      }},[_c('div',{staticClass:"caption-content",domProps:{"innerHTML":_vm._s(_vm.currentSubtitle[0])}})]),_c('div',{staticClass:"caption-container",class:{
        'captions-visible':
          _vm.areCaptionsVisible && _vm.currentSubtitleContainer === 1,
      }},[_c('div',{staticClass:"caption-content",domProps:{"innerHTML":_vm._s(_vm.currentSubtitle[1])}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }