export default [
  {
    name: `CSR_videos`,
    label: `CSR Videos`,
    src: `https://dctv-s3.reality-xr.com/csr-videos/csr-videos-all_FR.mp4`,
  },
  {
    name: `csr_dialog`,
    domain: require(`@/assets/csr-dialog/domain.png`),
    plantTop: require(`@/assets/csr-dialog/plant_vector_top.png`),
    plantBottom: require(`@/assets/csr-dialog/plant_vector_bottom.png`),
    cta: `VISITEZ VIRTUELLEMENT<br/>LE DOMAINE DES ALPES.`,
    buttonLabel: `COMMENCER`,
    videoTimings: [
      30,
      64,
      136,
    ],
  },
];
