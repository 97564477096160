export default [
  {
    name: `actifs-excellence-absolue`,
    title: `Outstanding active ingredients`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/actifs_excellence.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/actifs_excellence.mp4`,
  },
  {
    name: `annee-domaine-clarins`,
    title: `One year, four seasons`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/annee_au_domaine.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/annee_au_domaine.mp4`,
  },
  // {
  //   name: `beaute-au-sommet`,
  //   title: `La beauté au sommet`,
  //   subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/.vtt`,
  // },
  {
    name: `ceuillette-au-sechage`,
    title: `From the field to the drying racks`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/cueillette_au_sechage.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/cueillette_au_sechage.mp4`,
  },
  {
    name: `cultures-100-bio`,
    title: `100% organic crops`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/cultures_100_bio.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/cultures_100_bio.mp4`,
  },
  {
    name: `debut-longue-aventure`,
    title: `The discovery of the Domain by Christian Courtin-Clarins`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/intro_courtin.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/intro_courtin.mp4`,
  },
  {
    name: `engagement-preservation-alpes`,
    title: `A new biodiversity commitment `,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/engagement_environnemental.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/engagement_environnemental.mp4`,
  },
  {
    name: `engages-sauvegarde-alpes`,
    title: `Committed to safeguarding the French Alps`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/sauvegarde_alpes.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/sauvegarde_alpes.mp4`,
  },
  {
    name: `equipe-petits-soins`,
    title: `A dedicated team`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/equipe_devouee.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/equipe_devouee.mp4`,
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    title: `Gentiana lutea (great yellow gentian)`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/gentiane_jaune.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/gentiane_jaune.mp4`,
    productsCTA: `Find <b>Gentian</b> in`,
    products: [
      {
        name: `gentiane_mousse`,
        label: `Hydrating Gentle Foaming Cleanser*`,
        desc: `Rebalances normal to dry skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/mousse.png`,
      },
      {
        name: `gentiane_lait`,
        label: `Velvet Cleansing Milk`,
        desc: `Removes make-up, providing softness and comfort`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/lait.png`,
      },
      {
        name: `gentiane_huile`,
        label: `Total Cleansing Oil`,
        desc: `Removes long-hold and/or waterproof make-up`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/huile.png`,
      },
      {
        name: `gentiane_cleaning`,
        label: `Gentle Renewing Cleansing Mousse`,
        desc: `Gently cleanses and exfoliates skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/cleaning.png`,
      },
    ],
    products_extra: `<i>*Gentian is also found in Soothing Gentle Foaming Cleanser and Purifying Gentle Foaming Cleanser</i>`,
  },
  {
    name: `gypaete-barbu`,
    title: `The bearded vulture`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/gypaete_barbu.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/gypaete_barbu.mp4`,
  },
  {
    name: `joubarbe-des-toits`,
    title: `Sempervivum tectorum (common houseleek)`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/joubarbe.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/joubarbe.mp4`,
    productsCTA: `Find Houseleek in the ClarinsMen range`,
    products: [
      {
        name: `joubarbe_baume_hydratant`,
        label: `Super Moisture Balm`,
        desc: `Super hydration and comfort`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/baume_hydratant.png`,
      },
      {
        name: `joubarbe_gel_energisant`,
        label: `Energizing Gel`,
        desc: `Energizes, hydrates and protects`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_energisant.png`,
      },
      {
        name: `joubarbe_gel_energisant_yeux`,
        label: `Energizing Eye Gel`,
        desc: `Visibly refreshes (dark circles, puffiness), hydrates and strengthens the eye contour`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_energisant_yeux.png`,
      },
      {
        name: `joubarbe_gel_hydratant`,
        label: `Super Moisture Gel`,
        desc: `Super hydration and freshness`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_hydratant.png`,
      },
    ],
  },
  {
    name: `laboratoire-ciel-ouvert`,
    title: `An open-air laboratory`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/jardin_collection.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/jardin_collection.mp4`,
  },
  {
    name: `lieu-culture-experimentation`,
    title: `Dedicated to innovation and experimentation`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/milieu_culture.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/milieu_culture.mp4`,
  },
  {
    name: `melisse`,
    title: `Lemon balm`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/melisse.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/melisse.mp4`,
    productsCTA: `Find <b>Lemon balm</b> in`,
    products: [
      {
        name: `melisse_hydratant`,
        label: `Hydrating Gentle Foaming Cleanser*`,
        desc: `Rebalances normal to dry skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/hydratant.png`,
      },
      {
        name: `melisse_apaisant`,
        label: `Gentle Renewing Cleansing Mousse`,
        desc: `Gently cleanses and exfoliates skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/apaisant.png`,
      },
      {
        name: `melisse_purifiant`,
        label: `Velvet Cleansing Milk`,
        desc: `Removes make-up, providing softness and comfort`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/purifiant.png`,
      },
      {
        name: `melisse_oil`,
        label: `Total Cleansing Oil`,
        desc: `Removes long-hold and/or waterproof make-up`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/huile.png`,
      },
    ],
    products_extra: `<i>*Lemon Balm is also found in Soothing Gentle Foaming Cleanser and Purifying Gentle Foaming Cleanser</i>`,
  },
  {
    name: `partenaires-locaux`,
    title: `Trusted local partners`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/partenaires_locaux.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/partenaires_locaux.mp4`,
  },
  {
    name: `plantes-sauvages`,
    title: `Wild plants`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/plantes_sauvages.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/plantes_sauvages.mp4`,
  },
  {
    name: `production-circuit-court`,
    title: `Short circuit production`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/production_circuit_court.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/production_circuit_court.mp4`,
  },
  {
    name: `respect-sols`,
    title: `Respect for the soil`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/respect_sols.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/respect_sols.mp4`,
  },
  {
    name: `richesse-purete-alpes`,
    title: `The richness and purity of the French Alps`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/richesse_purete.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/richesse_purete.mp4`,
  },
  {
    name: `saponaire-saponaria-officinalis`,
    title: `Saponaria officinalis (common soapwort)`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/saponaire.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/saponaire.mp4`,
    productsCTA: `Find <b>Soapwort</b> in`,
    products: [
      {
        name: `saponaire_hydratant`,
        label: `Hydrating Gentle Foaming Cleanser`,
        desc: `Rebalances normal to dry skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/hydratant.png`,
      },
      {
        name: `saponaire_apaisant`,
        label: `Soothing Gentle Foaming Cleanser`,
        desc: `Rebalances very dry or sensitive skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/apaisant.png`,
      },
      {
        name: `saponaire_purifiant`,
        label: `Purifying Gentle Foaming Cleanser`,
        desc: `Rebalances combination to oily skin`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/purifiant.png`,
      },
    ],
  },
  {
    name: `tracabilite-exemplaire`,
    title: `Traceability is key`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/tracabilite_exemplaire.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/tracabilite_exemplaire.mp4`,
  },
  {
    name: `sources-energie-propres`,
    title: `A source of clean energy`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/source_energie_propre.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/source_energie_propre.mp4`,
  },
  {
    name: `sourcing-responsable`,
    title: `Responsible sourcing`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/sourcing_responsable.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/sourcing_responsable.mp4`,
  },
  {
    name: `voilages-protecteurs`,
    title: `Protective netting`,
    subtitles: `https://dctv-s3.reality-xr.com/videos/en/subtitles/voilages_protecteurs.vtt`,
    video: `https://dctv-s3.reality-xr.com/videos/en/voilages_protecteurs.mp4`,
  },
];
