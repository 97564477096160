<template>
  <div id="app">
    <loading-screen
      :config="loadingConfig"
      :is-preloading="isPreloading"
      :visible="isLoadingScreenOpened"
      @close="closeLoadingScreen"
    />
    <template v-if="isVisitStarted">
      <audio
        ref="backgroundSound"
        crossorigin="anonymous"
        :src="currentSound ? currentSound.src : ``"
        :loop="currentSound ? currentSound.loop : false"
      />
      <clarins-visit
        :app-audio-context="appAudioContext"
        :visit="visit"
        :dialogs="dialogs"
        :scenes="scenes"
        :csr-video="csrVideo"
        :csr-dialog="csrDialog"
        @dialog-opened="setBackgroundSoundGain"
        @dialog-closed="setBackgroundSoundGain"
        @play-sound="playSound"
      />
    </template>
    <pointer-cross v-if="hasPointerCross" />
  </div>
</template>

<script>
  import axios from "axios";
  import { v4 as uuidv4 } from "uuid";
  import mergeConfigFiles from "@/lib/mergeConfigFiles";

  // import loadTexture from '@/three/loadTexture';

  import PointerCross from "@/components/PointerCross";
  import LoadingScreen from "@/components/LoadingScreen";
  import ClarinsVisit from "@/components/ClarinsVisit";

  import langs from "@/visit/langs.js";
  import fonts from "@/visit/fonts.js";
  import loadTexture from "./three/loadTexture";
  // import dialogs from '@/visit/dialogs.js';
  // import scenes from '@/visit/scenes.js';
  // import sounds from '@/visit/sounds.js';
  // import csrVideos from '@/visit/csrVideos.js';

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  let DEFAULT_LANG = langs.find((lang) => lang.isDefault === true);
  if (!DEFAULT_LANG) {
    DEFAULT_LANG = langs[0];
  }

  const visitFiles = {};

  require
    .context(`@/visit`, true, /^.*\.js$/)
    .keys()
    .map((fileName) => fileName.replace(/^\W*/g, ``))
    .forEach(
      (fileName) => (visitFiles[fileName] = require(`@/visit/${fileName}`))
    );

  const loadVisitConfigItem = (item, lang, isMobile) => {
    const platformFileKey = isMobile ? `.mobile` : ``;
    const langFileKey = `.${lang.key}`;
    const defaultLangFileKey = `.${DEFAULT_LANG.key}`;
    const itemSpecificConfig =
      visitFiles[`${item}/${item}${platformFileKey}${langFileKey}.js`];
    const itemLangConfig = visitFiles[`${item}/${item}${langFileKey}.js`];
    const itemDefaultLangConfig =
      visitFiles[`${item}/${item}${defaultLangFileKey}.js`];
    const itemPlatformConfig = platformFileKey
      ? visitFiles[`${item}/${item}${platformFileKey}.js`]
      : null;
    const itemGlobalConfig = visitFiles[`${item}/${item}.js`];
    const itemRes = mergeConfigFiles(
      itemGlobalConfig?.default,
      itemPlatformConfig?.default,
      itemDefaultLangConfig?.default,
      itemLangConfig?.default,
      itemSpecificConfig?.default
    );
    return itemRes;
  };

  export default {
    components: {
      PointerCross,
      LoadingScreen,
      ClarinsVisit,
    },

    data: () => ({
      // hasPointerCross: true,
      // hasPreloading: false,
      // isLoadingScreenOpened: false,
      // isPreloading: false,
      // isVisitStarted: true,
      hasPointerCross: false,
      hasPreloading: true,
      isLoadingScreenOpened: true,
      isPreloading: true,
      isVisitStarted: false,
      visit: null,
      loadingConfig: null,
      dialogs: null,
      sounds: null,
      scenes: null,
      csrVideos: null,
      currentSoundName: null,
      appAudioContext: null,
      backgroundSoundGain: null,
    }),

    computed: {
      urlLang() {
        const url = window.location.href
          .replace(/\?.*$/g, ``)
          .replace(/\/$/g, ``);
        const langCandidateKey = url.replace(/.*\/(.*)$/g, `$1`);
        const urlLang = langs.find((lang) => lang.key === langCandidateKey);
        return urlLang || null;
      },
      langUrl() {
        return `//${window.location.host}/${this.lang.key}${window.location.search}`;
      },
      csrVideo() {
        return this.csrVideos[0];
      },
      csrDialog() {
        return this.csrVideos[1];
      },
      currentSound() {
        if (!this.currentSoundName) {
          return null;
        }

        const sound = this.sounds.find(
          (sound) => sound.name === this.currentSoundName
        );

        return sound || null;
      },
    },

    methods: {
      loadVisitConfig() {
        const dialogs = loadVisitConfigItem(`dialogs`, this.lang, false);
        const sounds = loadVisitConfigItem(`sounds`, this.lang, false);
        const scenes = loadVisitConfigItem(`scenes`, this.lang, false);
        const csrVideos = loadVisitConfigItem(`csrVideos`, this.lang, false);
        const loading = loadVisitConfigItem(`loading`, this.lang, false);
        const onboarding = loadVisitConfigItem(`onboarding`, this.lang, false);
        const fabButtons = loadVisitConfigItem(`fabButtons`, this.lang, false);
        const icons = loadVisitConfigItem(`icons`, this.lang, false);

        this.dialogs = dialogs;
        this.sounds = sounds;
        this.loadingConfig = loading;
        console.log(this.lang);
        this.visit = {
          //introVideo: `https://dctv-s3.reality-xr.com/intro_video.mp4`,
           introVideo:
            this.lang.shortLabel === "FR"
              ? `https://dctv-s3.reality-xr.com/intro_video.mp4`
              : `https://dctv-s3.reality-xr.com/intro_video_en.mp4`,
          onboardingConfig: onboarding,
          fabButtons,
          icons,
        };

        this.scenes = scenes.map((scene) => ({
          ...scene,
          dialogHotspots: (scene.dialogHotspots || []).map((hotspot) => {
            const dialog = dialogs.find((dialog) => dialog.name === hotspot.to);

            return {
              ...hotspot,
              uuid: uuidv4(),
              sceneName: scene.name,
              legend: dialog?.title || null,
              icon: dialog?.icon || null,
            };
          }),
          hubHotspots: (scene.hubHotspots || []).map((hotspot) => {
            const hotspotScene = scenes.find(
              (scene) => scene.name === hotspot.to
            );

            return {
              ...hotspot,
              uuid: uuidv4(),
              sceneName: scene.name,
              legend: hotspotScene?.title || null,
            };
          }),
          sceneHotspots: (scene.sceneHotspots || []).map((hotspot) => {
            const hotspotScene = scenes.find(
              (scene) => scene.name === hotspot.to
            );

            return {
              ...hotspot,
              uuid: uuidv4(),
              sceneName: scene.name,
              legend: hotspotScene?.title || null,
              xRotation: hotspot.xRotation || 0,
              yRotation: hotspot.yRotation || 0,
              zRotation: hotspot.zRotation || 0,
            };
          }),
          isVisible: false,
          isMounted: false,
        }));

        this.csrVideos = csrVideos.map((csrVideo) => ({
          ...csrVideo,
          uuid: uuidv4(),
        }));
      },
      setLang(lang) {
        this.lang = lang;
        history.pushState({}, ``, this.langUrl);
        this.loadVisitConfig();
      },
      setupExperienceAudio() {
        this.appAudioContext = new (AudioContext ||
          window.webkitAudioContext)();
      },
      async preloadFonts() {
        if (!fonts.length) {
          return Promise.resolve();
        }

        await Promise.all(
          fonts.map(async (font) => {
            const { data } = await axios.get(font.url, {
              responseType: `arraybuffer`,
            });
            const base64Font = arrayBufferToBase64(data);
            const style = document.createElement(`style`);
            style.innerText = `
          @font-face {
            font-family: '${font.name}';
            src: url('data:font/truetype;charset=utf-8;base64,${base64Font}');
            font-weight: ${font.weight};
          }
        `
              .replace(/[\r\n]/g, ``)
              .replace(/ +/g, ` `);
            document.head.appendChild(style);
          })
        );
        const style = document.createElement(`style`);
        style.innerText = `
        html, body, #app {
          font-family: ${fonts[0].name}, Avenir, Helvetica, Arial, sans-serif;
        }
      `
          .replace(/[\r\n]/g, ``)
          .replace(/ +/g, ` `);
        document.head.appendChild(style);
      },
      preloadSceneDialogs(sceneName) {
        const scene = this.scenes.find((s) => s.name === sceneName);
        const dialogNames = scene.dialogHotspots.map((d) => d.to);
        const dialogs = this.dialogs.filter((d) =>
          dialogNames.includes(d.name)
        );

        return Promise.all(
          dialogs.map(async (dialog) => {
            const { data } = await axios.get(dialog.video, {
              responseType: `blob`,
            });
            const b64Video = URL.createObjectURL(data);
            dialog.$video = dialog.video;
            dialog.video = b64Video;
          })
        );
      },
      preloadDialogs() {
        return Promise.all(
          this.dialogs.map(async (dialog) => {
            const { data } = await axios.get(dialog.video, {
              responseType: `blob`,
            });
            const b64Video = URL.createObjectURL(data);
            dialog.$video = dialog.video;
            dialog.video = b64Video;
          })
        );
      },
      async preloadVideo(vid) {
        const { data } = await axios.get(vid, { responseType: `blob` });
        const base64Vid = URL.createObjectURL(data);

        return base64Vid;
      },
      async preloadIntroVideo() {
        this.visit.$introVideo = this.visit.introVideo;
        this.visit.introVideo = await this.preloadVideo(this.visit.introVideo);
      },
      preloadScenes() {
        return Promise.all(
          this.scenes.map(async (scene) => {
            if (scene.vid) {
              scene.$vid = scene.vid;
              scene.vid = await this.preloadVideo(scene.vid);
            } else {
              await loadTexture(scene.image);
            }
          })
        );
      },
      preloadSounds() {
        return Promise.all(
          this.sounds.map(async (sound) => {
            const { data } = await axios.get(sound.src, {
              responseType: `blob`,
            });
            const base64Sound = URL.createObjectURL(data);
            sound.$src = sound.src;
            sound.src = base64Sound;
          })
        );
      },
      async preloadCsrVideos() {
        const { data } = await axios.get(this.csrVideos[0].src, {
          responseType: `blob`,
        });
        const base64Video = URL.createObjectURL(data);
        this.csrVideos[0].$src = this.csrVideos[0].src;
        this.csrVideos[0].src = base64Video;
      },
      async wait(ms) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(), ms);
        });
      },
      async preload() {
        await this.preloadFonts();
        if (this.hasPreloading) {
          this.isPreloading = true;
          await this.wait(1000);
          await Promise.all([
            this.preloadIntroVideo(),
            this.preloadSounds(),
            this.preloadDialogs(),
            this.preloadScenes(),
            this.preloadCsrVideos(),
          ]);
        }
        this.isPreloading = false;
      },
      enterFullscreen() {
        const fullscreenElement = document.documentElement;
        if (fullscreenElement.requestFullscreen) {
          fullscreenElement.requestFullscreen();
        } else if (fullscreenElement.msRequestFullscreen) {
          fullscreenElement.msRequestFullscreen();
        } else if (fullscreenElement.mozRequestFullScreen) {
          fullscreenElement.mozRequestFullScreen();
        } else if (fullscreenElement.webkitRequestFullscreen) {
          fullscreenElement.webkitRequestFullscreen();
        }
      },
      closeLoadingScreen() {
        this.enterFullscreen();
        this.isLoadingScreenOpened = false;
        this.isVisitStarted = true;
        if (this.appAudioContext.state === `suspended`) {
          this.appAudioContext.resume();
        }
      },
      initBackgroundSoundGain() {
        const bgSoundNode = this.appAudioContext.createMediaElementSource(
          this.$refs[`backgroundSound`]
        );
        this.backgroundSoundGain = this.appAudioContext.createGain();
        this.backgroundSoundGain.gain.value = 0;

        bgSoundNode
          .connect(this.backgroundSoundGain)
          .connect(this.appAudioContext.destination);
      },
      setBackgroundSoundGain(value, from) {
        from;
        try {
          // console.log(`setBGSoundGain from ${from}`);
          this.backgroundSoundGain.gain.linearRampToValueAtTime(
            value,
            this.appAudioContext.currentTime + 0.5
          );
        } catch (e) {
          console.log(e);
        }
      },
      playSound(soundName) {
        if (!soundName || soundName === this.currentSoundName) {
          return;
        }
        this.currentSoundName = soundName;
        setTimeout(() => {
          try {
            this.$refs[`backgroundSound`].play();
            if (this.backgroundSoundGain === null) {
              this.initBackgroundSoundGain();
            }
          } catch (e) {} // eslint-disable-line
        });
      },
    },

    created() {
      this.setLang(this.urlLang || DEFAULT_LANG);
    },

    mounted() {
      document.addEventListener("gesturestart", (e) => {
        e.preventDefault();
      });
      if (process.env.VUE_APP_DISABLE_PRELOAD === `1`) {
        this.isPreloading = false;
      } else {
        this.preload();
      }
      this.setupExperienceAudio();
    },
  };
</script>

<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #000;
  }

  html,
  body,
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #000;
    overflow: hidden;
  }
</style>
