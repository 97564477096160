export default {
  name: `fab-buttons-config`,
  csrFabButton: {
    cta: `END`,
    label: [`the Virtual`, `Visit`],
  },
  hubFabButton: {
    cta: `OPEN`,
    label: [`the DOMAINE `, `plan`],
  },
  csrDialogFabButton: {
    cta: `DISCOVER`,
    label: [`A 360°`, `tour of Le`, `Domaine des Alpes`],
  },
};
