<template>
  <div
    class="dialog"
    :class="{
      visible,
      bordered: hasVideo,
    }"
    @click="close"
  >
    <div class="dialog-content" @click.stop="" v-if="dialog !== null">
      <div class="dialog-video-wrapper" :class="{ visible: isVideoVisible }">
        <div class="dialog-title" v-html="dialog.title" />
        <div class="solo-video-wrapper" v-if="hasVideo">
          <video
            :src="video"
            ref="dialogVideo"
            :controls="false"
            @ended="videoEnded"
            crossorigin="anonymous"
            playsinline
            webkit-playsinline
          >
            <track kind="metadata" :src="dialog.subtitles" default />
          </video>
        </div>
      </div>
      <div
        class="dialog-products-wrapper"
        :class="{ visible: dialog.hasProducts && !isVideoVisible }"
        v-if="dialog.hasProducts"
      >
        <div class="products-title" v-html="products.title" />
        <div class="flower-underline">
          <div class="line" />
          <img :src="flowerIcon" alt="flower-icon" />
        </div>
        <div
          class="products-wrapper"
          :class="{
            podium: products.products.length === 3,
            table: products.products.length === 4,
          }"
        >
          <template v-for="(p, idx) in products.products">
            <div
              class="dialog-product"
              :class="{
                upper: idx === 1 && products.products.length === 3,
              }"
              :key="`${dialog.name}-${p.name}`"
            >
              <img class="product-image" :src="p.image" :alt="p.label" />
              <div class="product-label">{{ p.label }}</div>
              <div class="product-desc">{{ p.desc }}</div>
            </div>
          </template>
          <div
            v-if="products.products_extra"
            v-html="products.products_extra"
          />
        </div>
      </div>
      <a class="close-button" @click.prevent="close">×</a>
    </div>
    <div class="sound-visualizer" :class="{ visible: isVideoVisible }">
      <div
        class="sound-dot"
        :class="`sound-dot-${i}`"
        v-for="i in 16"
        :key="`sound-dot-${i}`"
      />
    </div>
    <div
      class="caption-wrapper"
      :class="{ visible: isVideoVisible }"
      v-if="dialog !== null && dialog.subtitles"
    >
      <div
        class="caption-container"
        :class="{
          'captions-visible':
            areCaptionsVisible && currentSubtitleContainer === 0,
        }"
      >
        <div class="caption-content" v-html="currentSubtitle[0]" />
      </div>
      <div
        class="caption-container"
        :class="{
          'captions-visible':
            areCaptionsVisible && currentSubtitleContainer === 1,
        }"
      >
        <div class="caption-content" v-html="currentSubtitle[1]" />
      </div>
    </div>
  </div>
</template>

<script>
  import arrowImg from "@/assets/hotspots/arrow.png";

  export default {
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      appAudioContext: {
        type: AudioContext,
        required: true,
      },
      dialog: {
        type: Object,
        required: false,
        default: () => null,
      },
      flowerIcon: {
        type: String,
        required: false,
        default: () => ``,
      },
    },

    data: () => ({
      currentImageIndex: 0,
      arrowImg,
      areCaptionsVisible: false,
      cues: [],
      currentSubtitle: [``, ``],
      currentSubtitleContainer: 0,
      dialogAudioNode: null,
      dialogAudioAnalyser: null,
      dataArray: null,
      isVideoVisible: true,
    }),

    computed: {
      video() {
        return this.dialog.video;
      },
      hasVideo() {
        return Boolean(this.dialog?.video);
      },
      products() {
        if (!this.dialog.hasProducts) {
          return null;
        }
        return {
          title: this.dialog.productsCTA,
          products: this.dialog.products,
          products_extra: this.dialog.products_extra || null,
        };
      },
      dots() {
        return document.querySelectorAll(`.sound-dot`);
      },
      trackElement() {
        return this.$el.querySelector(`track`);
      },
    },

    methods: {
      videoEnded() {
        if (this.dialog.hasProducts) {
          this.isVideoVisible = false;
        } else {
          this.close();
        }
      },
      close() {
        if (this.dialog.name === `debut-longue-aventure`) {
          this.$emit(`courtin-intro-finished`);
        }
        this.$emit(`close`);
      },
      toggleVideo() {
        if (!this.$refs[`dialogVideo`]) {
          return;
        }

        if (this.$refs[`dialogVideo`].paused) {
          this.$refs[`dialogVideo`].play();
        } else {
          this.$refs[`dialogVideo`].pause();
        }
      },
      async setCurrentSubtitle(text) {
        const currentSubIndex = (this.currentSubtitleContainer + 1) % 2;

        this.$set(this.currentSubtitle, currentSubIndex, text);

        this.currentSubtitleContainer = currentSubIndex;
      },
      async getSubtitlesData(e) {
        const track = e.target.track;

        if (typeof track === `undefined`) {
          return;
        }

        track.mode = "hidden";
        this.cues = track.cues;

        for (let i = 0; i < this.cues.length; i++) {
          const cue = this.cues[i];

          cue.onenter = (evt) => {
            this.setCurrentSubtitle(evt.target.text);
          };
        }

        this.areCaptionsVisible = true;
      },
      analyseAudio() {
        if (this.visible) {
          requestAnimationFrame(this.analyseAudio);
        }
        this.dialogAudioAnalyser.getByteFrequencyData(this.dataArray);

        for (let i = 64, d = 0; d < this.dots.length; i += 8, d++) {
          this.dots[d].style.height = `${Math.floor(
            (this.dataArray[i] / 255) * 150
          )}%`;
        }
      },
      initAudioAnalyser() {
        this.dialogAudioNode = this.appAudioContext.createMediaElementSource(
          this.$refs[`dialogVideo`]
        );
        this.dialogAudioNode.connect(this.dialogAudioAnalyser);
        this.dialogAudioAnalyser.connect(this.appAudioContext.destination);
        this.dialogAudioAnalyser.fftSize = 512;
        this.dataArray = new Uint8Array(
          this.dialogAudioAnalyser.frequencyBinCount
        );
      },
    },

    mounted() {
      this.dialogAudioAnalyser = this.appAudioContext.createAnalyser();
    },

    watch: {
      visible(visible) {
        if (visible) {
          if (!this.dialogAudioNode) {
            this.initAudioAnalyser();
          }
          if (this.appAudioContext.state === `suspended`) {
            this.appAudioContext.resume();
          }
          this.analyseAudio();
          setTimeout(() => {
            if (!this.areCaptionsVisible) {
              this.areCaptionsVisible = true;
            }
            if (this.$refs[`dialogVideo`].readyState === 4) {
              this.getSubtitlesData({ target: this.trackElement });
            }
            this.$refs[`dialogVideo`].play();
          }, 500);
        } else {
          setTimeout(() => {
            this.$refs[`dialogVideo`].pause();
            this.$refs[`dialogVideo`].currentTime = 0;
            this.cues = [];
            this.currentSubtitle = [``, ``];
            this.currentSubtitleContainer = 0;
            this.areCaptionsVisible = false;
            this.isVideoVisible = true;
          }, 500);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    // &.bordered {
    //   .dialog-content {
    //   }
    // }

    .dialog-content {
      width: 90%;
      max-width: 65vw;
      // max-width: calc(74vw - 6em);
      position: relative;
      padding: 4.4vw 4.4vw 5vw;
      color: #d4203f;
      background-color: #ffffff;
      outline: 1.8vw solid rgba(255, 255, 255, 0.5);

      .dialog-video-wrapper {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
        }

        .dialog-title {
          font-weight: 700;
          font-size: 3.9vw;
          margin-bottom: 1em;
        }

        .solo-video-wrapper {
          position: relative;
          width: 100%;

          video {
            width: 100%;
          }
        }
      }

      .dialog-products-wrapper {
        $products-wrapper-padding: 4.4vw;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - (2 * $products-wrapper-padding));
        height: calc(100% - (2 * $products-wrapper-padding));
        padding: $products-wrapper-padding;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
        }

        .products-title {
          font-size: 3.2vw;
        }

        .flower-underline {
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          margin-top: 4.4vw;

          .line {
            width: 40%;
            height: 2px;
            background-color: #d4203f;
          }

          img {
            width: 10%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .products-wrapper {
          width: 100%;
          margin-top: 3vh;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .dialog-product {
            height: 50%;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .product-image {
              width: 80%;
            }

            .product-label {
              font-size: 2.5vw;
              font-weight: 200;
              margin-top: 2em;
            }

            .product-desc {
              font-size: 1.75vw;
              color: #000;
              font-weight: 200;
              font-style: italic;
              margin-top: 0.5em;
            }

            &.upper {
              transform: translateY(-40%);
            }
          }

          &.podium {
            .dialog-product {
              width: calc(100% / 3);

              .product-image {
                width: 100%;
              }

              &:not(.upper) {
                transform: translateY(10%);
                // margin-top: 30%;
              }
            }
          }

          &.table {
            margin-top: 40px;
            flex-wrap: wrap;
            align-items: flex-start;

            .dialog-product {
              width: 40%;
              height: 40%;
              justify-content: flex-start;

              .product-label {
                margin-top: 0;
              }

              .product-image {
                width: 70%;
              }
            }
          }
        }
      }

      .close-button {
        font-size: 5.5vw;
        line-height: 3.7vw;
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 1vw;
        color: #b7b7b7;
      }
    }

    .sound-visualizer {
      display: flex;
      height: 3.9vh;
      margin: 1.5vh 0;
      align-items: center;
      gap: 8px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.visible {
        opacity: 1;
      }

      .sound-dot {
        width: 0.9vw;
        min-height: 0.9vw;
        max-height: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        transition: height 0.1s linear;
        will-change: height;
      }
    }

    .caption-wrapper {
      height: 6.2vh;
      width: 90%;
      max-width: 78vw;
      position: relative;
      color: #ffffff;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.visible {
        opacity: 1;
      }

      .caption-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        text-align: center;
        font-size: 3.5vw;

        &.captions-visible {
          opacity: 1;
        }
      }
    }
  }
</style>
