<template>
  <div class="clarins-fab-button" :class="`${align} ${visible ? '' : 'hidden'} ${hasPulse ? 'has-pulse' : ''} ${enabled ? '' : 'disabled'}`" @click="forwardClick">
    <img :src="config.icon" :alt="config.icon" v-if="config.icon" />
    <div class="cta">{{ config.cta }}</div>
    <div class="label">
      <template v-for="(line, idx) in config.label">
        {{ line }}<br v-if="(idx < config.label.length)" :key="`${line}`" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      required: false,
      default: () => `left`
    },
    enabled: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    hasPulse: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },

  methods: {
    forwardClick() {
      this.$emit(`click`);
    }
  }
};
</script>

<style lang="scss" scoped>
$clarinsFabButtonSize: 33vw;
$clarinsFabButtonFontSize: calc($clarinsFabButtonSize * 0.1);
// $clarinsFabButtonBorderSize: calc()

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.clarins-fab-button {
  width: calc($clarinsFabButtonSize - 1.5em);
  height: $clarinsFabButtonSize;
  // border-radius: $clarinsFabButtonSize;
  // background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  font-size: $clarinsFabButtonFontSize;
  font-weight: 700;
  color: #000;
  padding-bottom: 1.5em;
  transform-style: preserve-3d;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  img {
    width: 3em;
  }

  .cta {
    color: #D4203F;
  }

  $beforeMultFactor: 1.6;
  $bigCircleSize: calc($clarinsFabButtonSize * $beforeMultFactor);
  $borderSize: 0.6em;
  $smallCircleSize: calc($bigCircleSize - ($borderSize * 2));

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: $bigCircleSize;
    height: $bigCircleSize;
    border-radius: $bigCircleSize;
    background: rgba(255, 255, 255, 0.6);
    transition: background-color 0.3s ease-in-out;
  }

  &.has-pulse::before {
    @include keyframes(pulseMap) {
      from { box-shadow: 0 0 0 0px #D4203F; }
      to { box-shadow: 0 0 0 40px rgba(0, 0, 0, 0); }
    }
    @include animate(pulseMap, 1.5s, ease, infinite);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width:  $smallCircleSize;
    height: $smallCircleSize;
    border-radius: $smallCircleSize;
    background: rgba(255, 255, 255, 0.6);
    transition: background-color 0.3s ease-in-out;
  }


  &.left {
    left: 0;
    align-items: flex-start;
    text-align: left;
    padding-left: 1.5em;

    &::before {
      right: 0;
      transform: translate3d(8%, 10%, -2px);
    }
    &::after {
      right: 0;
      transform: translate3d(calc($bigCircleSize * 0.08 - $borderSize), calc($bigCircleSize * 0.1 + $borderSize), -1px);
    }
  }

  &.right {
    right: 0;
    align-items: flex-end;
    text-align: right;
    padding-right: 1.5em;

    &::before {
      left: 0;
      transform: translate3d(-8%, 10%, -2px);
    }
    &::after {
      left: 0;
      transform: translate3d(calc(-1 * $bigCircleSize * 0.08 + $borderSize), calc($bigCircleSize * 0.1 + $borderSize), -1px);
    }
  }

  &.disabled {
    color: #777777;
    pointer-events: none;

    img {
      filter: grayscale(1) brightness(2);
    }

    &::before,
    &::after {
      background-color: rgba(200, 200, 200, 0.6);
    }

    .cta {
      color: #3d3d3d;
    }
  }
}
</style>
