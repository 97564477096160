
export default [
  {
    image: `https://dctv-s3.reality-xr.com/360/entree-domaine.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/entree-domaine.mp4`,
    rotation: -1.1,
    name: `entree-domaine`,
    sound: `entree_domaine`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `debut-longue-aventure`,
        x: 1.9900789357785116,
        y: 0.1980204514580563,
        z: -0.019332102188177082,
      },
      {
        to: `cultures-100-bio`,
        x: 418.51988996468333,
        y: 133.6191880391285,
        z: 238.7195306038309,
      },
      {
        to: `production-circuit-court`,
        x: -310.55071190427935,
        y: -6.108500417623618,
        z: 391.81748500850904,
      },
    ],
    sceneHotspots: [
      {
        to: `gentiane-et-saponaire`,
        x: 13.8,
				y: -2.4,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
      {
        to: `jardin-collection`,
        x: 463.86625552366837,
        y: 85.10474958301631,
        z: 166.08816509571915,
        xRotation: 45,
        yRotation: -5,
        zRotation: -36,
      },
      {
        to: `chalet`,
        x: -242.5963990734104,
        y: -93.6906572928623,
        z: 427.04689191310837,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/gentiane-et-saponaire.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/gentiane-et-saponaire.mp4`,
    rotation: 1.4,
    name: `gentiane-et-saponaire`,
    sound: `saponaire`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `saponaire-saponaria-officinalis`,
        x: 463.0041290506121,
        y: 40.96925431502049,
        z: 184.25172097691606,
      },
    ],
    sceneHotspots: [
      {
        to: `parcelle-cheval`,
        x: 497.8615908047626,
        y: 0.8726641829491307,
        z: -46.185223379073626,
        xRotation: 45,
        yRotation: -14,
        zRotation: -120,
      },
      {
        to: `entree-domaine`,
        x: -249.61019104549513,
        y: 27.91075249658197,
        z: 432.3375329777718,
        xRotation: 38,
        yRotation: 0,
        zRotation: -90,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/chalet.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/chalet.mp4`,
    rotation: 1.5,
    // rotation: -2.5,
    sound: `chalet`,
    name: `chalet`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `tracabilite-exemplaire`,
        x: 480.8640631186503,
        y: -2.6179819157097075,
        z: -136.97773166362373,
      },
      {
        to: `ceuillette-au-sechage`,
        x: -105.54509975384347,
        y: -23.55322535482139,
        z: 488.1654202146402,
      },
    ],
    sceneHotspots: [
      {
        to: `entree-domaine`,
        x: -385.25957505972946,
        y: -110.77424880973366,
        z: -298.8379588095126,
        xRotation: 45,
        yRotation: -5,
        zRotation: -124,
      },
      {
        to: `jardin-collection`,
        x: -317.58662821271884,
        y: -79.54875701183829,
        z: -377.90306804569326,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/haut-domaine.jpg`,
    vid: `https://dctv-s3.reality-xr.com/360/haut-domaine.mp4`,
    rotation: -0.2,
    name: `haut-domaine`,
    sound: `haut_domaine`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `gentiane-jaune-gentiana-lutea`,
        x: 498.568581525197,
        y: 27.09844196418622,
        z: -26.36368637096456,
      },
    ],
    sceneHotspots: [
      {
        to: `partenaires`,
        x: 455.2377705903957,
        y: 178.7312393582841,
        z: 103.98902011913573,
        xRotation: 45,
        yRotation: 0,
        zRotation: -80,
      },
      {
        to: `plantations`,
        x: 44.644054181799355,
        y: -50.734648412236005,
        z: -495.4118527818035,
        xRotation: 45,
        yRotation: 0,
        zRotation: -90,
      },
      {
        to: `jardin-collection`,
        x: -451.53853713031504,
        y: -214.73576746998793,
        z: 1.2246041361766167,
        xRotation: 30,
        yRotation: 0,
        zRotation: 180,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/jardin-collection.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/jardin-collection.mp4`,
    rotation: -1.1,
    name: `jardin-collection`,
    sound: `jardin_collection`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `laboratoire-ciel-ouvert`,
        x: 490.7122285167493,
        y: 68.02585610218178,
        z: -67.63129220775612,
      },
    ],
    sceneHotspots: [
      {
        to: `plantations`,
        x: 376.0712522668435,
        y: 117.57105655129499,
        z: -307.8107533531932,
        xRotation: 45,
        yRotation: -5,
        zRotation: -110,
      },
      {
        to: `chalet`,
        x: 0.42110206630299485,
        y: -0.6421406924504082,
        z: 1.8467074432228454,
        xRotation: 45,
        yRotation: -5,
        zRotation: -130,
      },
      {
        to: `entree-domaine`,
        x: -0.5089523981292644,
        y: -0.6698396814053207,
        z: 1.81446473034151,
        xRotation: 45,
        yRotation: -5,
        zRotation: 3,
      },
      {
        to: `gentiane-et-saponaire`,
        x: -399.03184974373,
        y: -221.53559541208975,
        z: 204.19491388256495,
        xRotation: 45,
        yRotation: -5,
        zRotation: -95,
      },
      {
        to: `parcelle-cheval`,
        x: -260.8728540375158,
        y: -46.18529372328077,
        z: -424.0427722176328,
        xRotation: 45,
        yRotation: -5,
        zRotation: -20,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/parcelle-cheval.jpg`,
    vid: `https://dctv-s3.reality-xr.com/360/parcelle-cheval.mp4`,
    rotation: -2.2,
    name: `parcelle-cheval`,
    sound: `parcelle_cheval`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `melisse`,
        x: 1.807949976779828,
        y: -0.8366075494283575,
        z: 0.17721368373039995,
      },
      {
        to: `respect-sols`,
        x: 1.9000447983465016,
        y: -0.5619842335996728,
        z: -0.27203581650545616,
      },
      {
        to: `plantes-sauvages`,
        x: 361.9657359635201,
        y: -108.25683113532473,
        z: -327.50765563712406,
      },
      {
        to: `actifs-excellence-absolue`,
        x: 164.00372899997708,
        y: -72.01629127559532,
        z: -466.8151996936375,
      },
    ],
    sceneHotspots: [
      {
        to: `gentiane-et-saponaire`,
        x: 454.35857267900195,
        y: -38.35951406340934,
        z: -205.15076191405979,
        xRotation: 35,
        yRotation: -5,
        zRotation: -96,
      },
      {
        to: `plantations`,
        x: 226.03855048544847,
        y: 73.90470556480527,
        z: -439.8234511594587,
        xRotation: 35,
        yRotation: -5,
        zRotation: -96,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/plantations.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/plantations.mp4`,
    rotation: 0,
    name: `plantations`,
    sound: `plantations`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `equipe-petits-soins`,
        x: 1.7321179483163927,
        y: -0.9998411301669271,
        z: 0.009226459057147756,
      },
      {
        to: `annee-domaine-clarins`,
        x: 1.7099626682933105,
        y: -0.020498520950696802,
        z: 1.037114980936085,
      },
      {
        to: `joubarbe-des-toits`,
        x: -1.845452182620728,
        y: -0.39954337609437474,
        z: 0.6592960884758078,
      },
      {
        to: `voilages-protecteurs`,
        x: -232.42380126431993,
        y: -89.88109368527185,
        z: 433.4749884408364,
      },
    ],
    sceneHotspots: [
      {
        to: `parcelle-cheval`,
        x: 401.7187464965456,
        y: -170.1897751065251,
        z: 244.24882632765008,
        xRotation: 45,
        yRotation: -5,
        zRotation: 7,
      },
      {
        to: `jardin-collection`,
        x: 317.69927954876863,
        y: -205.7571793025544,
        z: -326.69733843367914,
        xRotation: 45,
        yRotation: -5,
        zRotation: -130,
      },
      {
        to: `partenaires`,
        x: -463.1947499113235,
        y: 187.91417195546896,
        z: -11.785059731578054,
        xRotation: 45,
        yRotation: 0,
        zRotation: -90,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/partenaires.jpg`,
    // vid: `https://dctv-s3.reality-xr.com/360/partenaires.mp4`,
    rotation: -1.2,
    name: `partenaires`,
    sound: `partenaires`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [
      {
        to: `sourcing-responsable`,
        x: 1.1091782309680869,
        y: -0.7523825943361114,
        z: -1.4844676095107525,
      },
      {
        to: `engages-sauvegarde-alpes`,
        x: 476.72610433508015,
        y: 79.47382275360123,
        z: -128.12545782328928,
      },
      {
        to: `partenaires-locaux`,
        x: 430.9117557829843,
        y: 111.61166288152283,
        z: 227.7232869885005,
      },
      {
        to: `gypaete-barbu`,
        x: 156.1846238371893,
        y: 209.83182784848913,
        z: 426.1184897403511,
      },
      {
        to: `engagement-preservation-alpes`,
        x: -302.2312372752596,
        y: 93.3437537884325,
        z: 387.22502868971446,
      },
    ],
    sceneHotspots: [
      {
        to: `gentiane-et-saponaire`,
        x: 1.4010324695606482,
        y: -0.9200518912297642,
        z: -1.0911519310716198,
        xRotation: 52,
        yRotation: 0,
        zRotation: -85,
      },
      {
        to: `plantations`,
        x: 1.8385804580605911,
        y: -0.7799511146512299,
        z: -0.106292793697461,
        xRotation: 52,
        yRotation: 0,
        zRotation: -85,
      },
    ],
  },
  {
    image: `https://dctv-s3.reality-xr.com/360/hub.jpg`,
    rotation: 2.6,
    name: `hub`,
    camera: {
      azi: -30,
      pol: 0,
    },
    dialogHotspots: [],
    sceneHotspots: [],
    hubHotspots: [
      {
        to: `chalet`,
        x: 420.2422514678712,
        y: -188.74225418938514,
        z: 194.35228726393743,
      },
      {
        to: `entree-domaine`,
        x: 429.2899370599845,
        y: -236.65992743057387,
        z: 98.49989181511899,
      },
      {
        to: `jardin-collection`,
        x: 471.0526049563524,
        y: -135.93167362280803,
        z: 98.14287274141182,
      },
      {
        to: `haut-domaine`,
        x: 487.8962215769124,
        y: -38.82927477818275,
        z: 102.22311084668065,
      },
      {
        to: `plantations`,
        x: 497.21766070910377,
        y: -24.71545714144966,
        z: -46.51606235759596,
      },
      {
        to: `gentiane-et-saponaire`,
        x: 439.2644996483044,
        y: -204.51640411677764,
        z: -123.36830952828898,
      },
      {
        to: `parcelle-cheval`,
        x: 1.7901394958422259,
        y: -0.4358888407436794,
        z: -0.7780755130068505,
      },
      {
        to: `partenaires`,
        x: 488.97269063549584,
        y: 63.527866452661094,
        z: 82.88496846024945,
      },
    ],
  },
];
