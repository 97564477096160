export default {
  name: `onboarding-config`,
  introMessage: `https://dctv-s3.reality-xr.com/Wording_intro_360_en.png`,
  title: `Welcome to the domaine Clarins !`,
  subtitle: `Located at the heart of the French Alps, the Domaine is the ideal place to grow and harvest our own plants, without any air or soil pollution.`,
  sceneCTA: `Continue your visit in...`,
  instructions: [
    {
      name: `arrow`,
      legend: `Use the arrows to explore`,
    },
    {
      name: `hotspot`,
      legend: ` Click on areas of interest to learn more.`,
    },
    {
      name: `map`,
      legend: ` Select the map for an overall view.`,
    },
    {
      name: `headset`,
      legend: `Put on the audio headset for a more immersive experience.`,
    },
  ],
  startCTA: `START THE VISIT`,
};
