var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clarins-scene",class:{
    visible: _vm.visible,
  }},[_c('div',{staticClass:"scene-container",attrs:{"id":_vm.id}}),_c('video',{staticStyle:{"display":"none"},attrs:{"id":"sceneTex","muted":"true","loop":"true","crossorigin":"anonymous","playsinline":"","src":this.scene.vid},domProps:{"muted":true}}),_vm._l((_vm.dialogHotspots),function(hotspot){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(hotspot.sceneName === _vm.scene.name && _vm.hotspotsVisible),expression:"hotspot.sceneName === scene.name && hotspotsVisible"}],key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"dialog-hotspot",class:{
        disabled:
          !_vm.hotspotsClickable && hotspot.to !== "debut-longue-aventure",
        courtin: hotspot.to === "debut-longue-aventure",
      },on:{"mouseup":function($event){return _vm.openDialog(hotspot.to)},"touchend":function($event){return _vm.openDialog(hotspot.to)}}},[_c('img',{attrs:{"src":hotspot.icon ? hotspot.icon : _vm.hotspotImg}}),(hotspot.legend)?_c('div',{staticClass:"dialog-hotspot-legend",domProps:{"innerHTML":_vm._s(hotspot.legend)}}):_vm._e()])]}),_vm._l((_vm.hubHotspots),function(hotspot){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(hotspot.sceneName === _vm.scene.name && _vm.hotspotsVisible),expression:"hotspot.sceneName === scene.name && hotspotsVisible"}],key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"hub-hotspot",attrs:{"scene-name":hotspot.sceneName},on:{"mouseup":function($event){return _vm.goToScene(hotspot.to)},"touchend":function($event){return _vm.goToScene(hotspot.to)}}},[_vm._v(" "+_vm._s(hotspot.legend)+" ")])]}),_vm._l((_vm.sceneHotspots),function(hotspot){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(hotspot.sceneName === _vm.scene.name && _vm.hotspotsVisible),expression:"hotspot.sceneName === scene.name && hotspotsVisible"}],key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"scene-hotspot",class:{
        disabled:
          !_vm.hotspotsClickable && hotspot.to !== "debut-longue-aventure",
      },on:{"mouseup":function($event){return _vm.goToScene(hotspot.to)},"touchend":function($event){return _vm.goToScene(hotspot.to)}}},[_c('img',{style:(("transform: rotateX(" + (hotspot.xRotation) + "deg) rotateY(" + (hotspot.yRotation) + "deg) rotateZ(" + (hotspot.zRotation) + "deg);")),attrs:{"src":_vm.arrowImg}})])]}),_c('div',{staticClass:"swipe-cta",class:{ visible: _vm.isAutoSpinning && !_vm.isIntroSpinning }},[_c('img',{attrs:{"src":_vm.swipeGif,"alt":"swipe to discover"}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }