export default [
  {
    key: `en_US`,
    shortLabel: `US`,
    label: `ENGLISH`,
    isDefault: false,
  },
  {
    key: `fr_FR`,
    shortLabel: `FR`,
    label: `FRANÇAIS`,
    isDefault: true,
  },
];
