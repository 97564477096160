export default [
  {
    name: `actifs-excellence-absolue`,
    video: `https://dctv-s3.reality-xr.com/videos/actifs_excellence.mp4`,
    hasProducts: false,
  },
  {
    name: `annee-domaine-clarins`,
    video: `https://dctv-s3.reality-xr.com/videos/annee_au_domaine.mp4`,
    hasProducts: false,
  },
  // {
  //   name: `beaute-au-sommet`,
  //   video: `https://dctv-s3.reality-xr.com/videos/.mp4`,
  // },
  {
    name: `ceuillette-au-sechage`,
    video: `https://dctv-s3.reality-xr.com/videos/cueillette_au_sechage.mp4`,
    hasProducts: false,
  },
  {
    name: `cultures-100-bio`,
    video: `https://dctv-s3.reality-xr.com/videos/cultures_100_bio.mp4`,
    hasProducts: false,
  },
  {
    name: `debut-longue-aventure`,
    video: `https://dctv-s3.reality-xr.com/videos/intro_courtin.mp4`,
    icon: `https://dctv-s3.reality-xr.com/hotspots/red-video.png`,
    hasProducts: false,
  },
  {
    name: `engagement-preservation-alpes`,
    video: `https://dctv-s3.reality-xr.com/videos/engagement_environnemental.mp4`,
    hasProducts: false,
  },
  {
    name: `engages-sauvegarde-alpes`,
    video: `https://dctv-s3.reality-xr.com/videos/sauvegarde_alpes.mp4`,
    hasProducts: false,
  },
  {
    name: `equipe-petits-soins`,
    video: `https://dctv-s3.reality-xr.com/videos/equipe_devouee.mp4`,
    hasProducts: false,
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    video: `https://dctv-s3.reality-xr.com/videos/gentiane_jaune.mp4`,
    hasProducts: true,
    productsCTA: `Retrouvez la <b>Gentiane</b> dans`,
    products: [
      {
        name: `gentiane_mousse`,
        label: `Doux Nettoyant Moussant Hydratant*`,
        desc: `Rééquilibre les peaux normales à sèches`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/mousse.png`,
      },
      {
        name: `gentiane_cleaning`,
        label: `Mousse Nettoyante Peau Neuve`,
        desc: `Nettoie et gomme la peau en douceur`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/cleaning.png`,
      },
      {
        name: `gentiane_lait`,
        label: `Lait Velours Démaquillant`,
        desc: `Élimine le maquillage en apportant douceur & réconfort`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/lait.png`,
      },
      {
        name: `gentiane_huile`,
        label: `Huile Très Démaquillante`,
        desc: `Élimine le maquillage longue tenue et/ou waterproof`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/huile.png`,
      },
    ],
    products_extra: `*La Gentiane est également présente dans le Doux Nettoyant Moussant Apaisant et Purifiant.`,
  },
  {
    name: `gypaete-barbu`,
    video: `https://dctv-s3.reality-xr.com/videos/gypaete_barbu.mp4`,
    hasProducts: false,
  },
  {
    name: `joubarbe-des-toits`,
    video: `https://dctv-s3.reality-xr.com/videos/joubarbe.mp4`,
    hasProducts: true,
    productsCTA: `Retrouvez la <b>Joubarbe des Toits</b>,<br/>dans la gamme ClarinsMen`, // visuels manquants
    products: [
      {
        name: `joubarbe_baume_hydratant`,
        label: `Baume Super Hydratant`,
        desc: `Super Hydratation & confort`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/baume_hydratant.png`,
      },
      {
        name: `joubarbe_gel_energisant`,
        label: `Gel Énergisant`,
        desc: `Énergise, hydrate & protège`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_energisant.png`,
      },
      {
        name: `joubarbe_gel_energisant_yeux`,
        label: `Gel Énergisant Yeux`,
        desc: `Défatigue visiblement (cernes, poches), hydrate & fortifie le contour des yeux `,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_energisant_yeux.png`,
      },
      {
        name: `joubarbe_gel_hydratant`,
        label: `Gel Super Hydratant`,
        desc: `Super hydratation, fraîcheur`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/joubarbe/gel_hydratant.png`,
      },
    ],
  },
  {
    name: `laboratoire-ciel-ouvert`,
    video: `https://dctv-s3.reality-xr.com/videos/jardin_collection.mp4`,
    hasProducts: false,
  },
  {
    name: `lieu-culture-experimentation`,
    video: `https://dctv-s3.reality-xr.com/videos/milieu_culture.mp4`,
    hasProducts: false,
  },
  {
    name: `melisse`,
    video: `https://dctv-s3.reality-xr.com/videos/melisse.mp4`,
    hasProducts: true,
    productsCTA: `Retrouvez la <b>Mélisse</b> dans`,
    products: [
      {
        name: `melisse_hydratant`,
        label: `Doux Nettoyant Moussant Hydratant*`,
        desc: `Rééquilibre les peaux normales à sèches`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/hydratant.png`,
      },
      {
        name: `melisse_apaisant`,
        label: `Mousse Nettoyante Peau Neuve`,
        desc: `Nettoie et gomme la peau en douceur`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/apaisant.png`,
      },
      {
        name: `melisse_purifiant`,
        label: `Lait Velours Démaquillant`,
        desc: `Élimine le maquillage en apportant douceur & réconfort
        `,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/melisse/purifiant.png`,
      },
      {
        name: `melisse_oil`,
        label: `Huile Très Démaquillante`,
        desc: `Élimine le maquillage longue tenue et/ou waterproof`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/gentiane/huile.png`,
      },
    ],
    products_extra: `*La Mélisse est également présente dans le Doux Nettoyant Moussant Apaisant et Purifiant.`,
  },
  {
    name: `partenaires-locaux`,
    video: `https://dctv-s3.reality-xr.com/videos/partenaires_locaux.mp4`,
    hasProducts: false,
  },
  {
    name: `plantes-sauvages`,
    video: `https://dctv-s3.reality-xr.com/videos/plantes_sauvages.mp4`,
    hasProducts: false,
  },
  {
    name: `production-circuit-court`,
    video: `https://dctv-s3.reality-xr.com/videos/production_circuit_court.mp4`,
    hasProducts: false,
  },
  {
    name: `respect-sols`,
    video: `https://dctv-s3.reality-xr.com/videos/respect_sols.mp4`,
    hasProducts: false,
  },
  {
    name: `richesse-purete-alpes`,
    video: `https://dctv-s3.reality-xr.com/videos/richesse_purete.mp4`,
    hasProducts: false,
  },
  {
    name: `saponaire-saponaria-officinalis`,
    video: `https://dctv-s3.reality-xr.com/videos/saponaire.mp4`,
    hasProducts: true,
    productsCTA: `Retrouvez la <b>Saponaire</b> dans`,
    products: [
      {
        name: `saponaire_hydratant`,
        label: `Doux Nettoyant Moussant Hydratant`,
        desc: `Rééquilibre les peaux normales à sèches`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/hydratant.png`,
      },
      {
        name: `saponaire_apaisant`,
        label: `Doux Nettoyant Moussant Apaisant`,
        desc: `Rééquilibre les peaux très sèches ou sensibles`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/apaisant.png`,
      },
      {
        name: `saponaire_purifiant`,
        label: `Doux Nettoyant Moussant Purifiant`,
        desc: `Rééquilibre les peaux mixtes à grasses`,
        image: `https://dctv-s3.reality-xr.com/dialogs/products/saponaire/purifiant.png`,
      },
    ],
  },
  {
    name: `tracabilite-exemplaire`,
    video: `https://dctv-s3.reality-xr.com/videos/tracabilite_exemplaire.mp4`,
    hasProducts: false,
  },
  {
    name: `sources-energie-propres`,
    video: `https://dctv-s3.reality-xr.com/videos/source_energie_propre.mp4`,
    hasProducts: false,
  },
  {
    name: `sourcing-responsable`,
    video: `https://dctv-s3.reality-xr.com/videos/sourcing_responsable.mp4`,
    hasProducts: false,
  },
  {
    name: `voilages-protecteurs`,
    video: `https://dctv-s3.reality-xr.com/videos/voilages_protecteurs.mp4`,
    hasProducts: false,
  },
];
