export default [
  {
    name: `entree-domaine`,
    title: `Entrance to Le Domaine`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `Gentian and Soapwort`,
  },
  {
    name: `chalet`,
    title: `The chalet`,
  },
  {
    name: `haut-domaine`,
    title: `The top of Le Domaine`,
  },
  {
    name: `jardin-collection`,
    title: `The collection garden`,
  },
  {
    name: `parcelle-cheval`,
    title: `The horse patch`,
  },
  {
    name: `plantations`,
    title: `The plantations`,
  },
  {
    name: `partenaires`,
    title: `Our partners`,
  },
];
