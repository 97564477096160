<template>
  <div class="onboarding-screen" :class="{ visible }">
    <div class="card">
      <div class="title">{{ config.title }}</div>
      <div class="subtitle" :class="{ hidden: isSubtitleHidden }">
        {{ config.subtitle }}
      </div>
      <div class="instructions">
        <div class="instructions-container">
          <div class="instruction" :class="{ hidden: isInstructionHidden[0] }">
            <img :src="config.instructions[0].icon" alt="">
            <div class="legend">{{ config.instructions[0].legend }}</div>
          </div>
          <div class="instruction" :class="{ hidden: isInstructionHidden[1] === true }">
            <img :src="config.instructions[1].icon" alt="">
            <div class="legend">{{ config.instructions[1].legend }}</div>
          </div>
          <div class="instruction" :class="{ hidden: isInstructionHidden[2] === true }">
            <img :src="config.instructions[2].icon" alt="">
            <div class="legend">{{ config.instructions[2].legend }}</div>
          </div>
          <div class="instruction" :class="{ hidden: isInstructionHidden[3] === true }">
            <img :src="config.instructions[3].icon" alt="">
            <div class="legend">{{ config.instructions[3].legend }}</div>
          </div>
        </div>
      </div>
      <a class="continue-button" :class="{ hidden: isCTAHidden }" @click="close">
        {{ config.startCTA }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      typeo: Object,
      required: true,
    },
  },

  data: () => ({
    isSubtitleHidden: true,
    isInstructionHidden: [
      true,
      true,
      true,
      true,
    ],
    isCTAHidden: true,
  }),

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    close() {
      this.$emit(`close`);
    },
  },

  watch: {
    async visible(visible) {
      if (!visible) {
        await this.wait(500);
        this.isSubtitleHidden = true;
        for (let i = 0; i < 4; i++) this.isInstructionHidden[i] = true;
        this.isCTAHidden = true;
      } else {
        await this.wait(500);
        this.isSubtitleHidden = false;
        for (let i = 0; i < 4; i++) {
          setTimeout(() => {
            this.$set(this.isInstructionHidden, i, false);
          }, (i+1) * 1000)
        }
        setTimeout(() => {
          this.isCTAHidden = false;
        }, 5000)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.onboarding-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &, * {
    color: #222;
  }

  .logo-wrapper {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      width: 80%;
      max-width: 280px;
    }
  }

  .card {
    background-color: rgba(255, 255, 255, 0.9);
    border: 2vw solid rgba(255, 255, 255, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    width: 55%;
    padding: 8vw 10vw;

    .title {
      color: #D4203F;
      text-transform: uppercase;
      font-size: 3vw;
      font-weight: 700;
    }

    .subtitle {
      padding-top: 4vw;
      font-size: 2.7vw;
      font-weight: 500;
      text-align: center;
      transition: opacity 0.3s ease-in-out;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    .instructions {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 2vw 1vw;

      .instructions-container {
        padding: 1vw 2vw;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .instruction {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 2.1vw;
          text-align: left;
          padding-top: 4vw;
          font-style: italic;
          transition: opacity 0.3s ease-in-out;

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }

          img {
            height: 5.7vw;
            margin-right: 4vw;
          }
        }
      }
    }

    .continue-button {
      display: inline-block;
      margin-top: 4.8vw;
      padding: 3vw 8vw;
      font-weight: 700;
      color: #FFF;
      background-color: #D4203F;
      border-radius: 1vw;
      font-size: 2.1vw;
      text-decoration: none;
      text-transform: uppercase;
      transition: opacity 0.3s ease-in-out;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

</style>
