
export default [
  // {
  //   name: `default`,
  //   src: `https://dctv-s3.reality-xr.com/sounds/default.mp3`,
  //   loop: true,
  // },
  // {
  //   name: `horses`,
  //   src: `https://dctv-s3.reality-xr.com/sounds/horses.mp3`,
  //   loop: true,
  // },
  {
    name: `chalet`,
    src: `https://dctv-s3.reality-xr.com/sounds/chalet.mp3`,
    loop: true,
  },
  {
    name: `entree_domaine`,
    src: `https://dctv-s3.reality-xr.com/sounds/entree_domaine.mp3`,
    loop: true,
  },
  {
    name: `haut_domaine`,
    src: `https://dctv-s3.reality-xr.com/sounds/haut_domaine.mp3`,
    loop: true,
  },
  {
    name: `jardin_collection`,
    src: `https://dctv-s3.reality-xr.com/sounds/jardin_collection.mp3`,
    loop: true,
  },
  {
    name: `parcelle_cheval`,
    src: `https://dctv-s3.reality-xr.com/sounds/parcelle_cheval.mp3`,
    loop: true,
  },
  {
    name: `partenaires`,
    src: `https://dctv-s3.reality-xr.com/sounds/partenaires.mp3`,
    loop: true,
  },
  {
    name: `plantations`,
    src: `https://dctv-s3.reality-xr.com/sounds/plantations.mp3`,
    loop: true,
  },
  {
    name: `saponaire`,
    src: `https://dctv-s3.reality-xr.com/sounds/saponaire.mp3`,
    loop: true,
  },
];
