<template>
  <div
    class="clarins-scene"
    :class="{
      visible,
    }"
  >
    <div :id="id" class="scene-container"></div>
    <video
      id="sceneTex"
      muted="true"
      loop="true"
      crossorigin="anonymous"
      playsinline
      :src="this.scene.vid"
      style="display: none"
    />
    <template v-for="hotspot in dialogHotspots">
      <div
        class="dialog-hotspot"
        :class="{
          disabled:
            !hotspotsClickable && hotspot.to !== `debut-longue-aventure`,
          courtin: hotspot.to === `debut-longue-aventure`,
        }"
        :key="hotspot.uuid"
        :ref="hotspot.uuid"
        @mouseup="openDialog(hotspot.to)"
        @touchend="openDialog(hotspot.to)"
        v-show="hotspot.sceneName === scene.name && hotspotsVisible"
      >
        <img :src="hotspot.icon ? hotspot.icon : hotspotImg" />
        <div
          class="dialog-hotspot-legend"
          v-if="hotspot.legend"
          v-html="hotspot.legend"
        />
      </div>
    </template>
    <template v-for="hotspot in hubHotspots">
      <div
        class="hub-hotspot"
        :key="hotspot.uuid"
        :ref="hotspot.uuid"
        :scene-name="hotspot.sceneName"
        @mouseup="goToScene(hotspot.to)"
        @touchend="goToScene(hotspot.to)"
        v-show="hotspot.sceneName === scene.name && hotspotsVisible"
      >
        {{ hotspot.legend }}
      </div>
    </template>
    <template v-for="hotspot in sceneHotspots">
      <div
        class="scene-hotspot"
        :class="{
          disabled:
            !hotspotsClickable && hotspot.to !== `debut-longue-aventure`,
        }"
        :key="hotspot.uuid"
        :ref="hotspot.uuid"
        @mouseup="goToScene(hotspot.to)"
        @touchend="goToScene(hotspot.to)"
        v-show="hotspot.sceneName === scene.name && hotspotsVisible"
      >
        <img
          :src="arrowImg"
          :style="`transform: rotateX(${hotspot.xRotation}deg) rotateY(${hotspot.yRotation}deg) rotateZ(${hotspot.zRotation}deg);`"
        />
      </div>
    </template>
    <div
      class="swipe-cta"
      :class="{ visible: isAutoSpinning && !isIntroSpinning }"
    >
      <img :src="swipeGif" alt="swipe to discover" />
    </div>
  </div>
</template>

<script>
  import * as THREE from "three";
  import { v4 as uuidv4 } from "uuid";
  import loadTexture from "@/three/loadTexture";
  import hotspotImg from "@/assets/hotspots/red.png";
  import hotspotImgHover from "@/assets/hotspots/red-hover.png";
  import arrowImg from "@/assets/hotspots/arrow.png";
  import arrowImgHover from "@/assets/hotspots/arrow-hover.png";

  let mesh = null;
  let geometry = null;
  let material = null;
  let camera = null;
  let threeScene = null;
  let renderer = null;
  let draggingXOrigin = null;
  let draggingYOrigin = null;
  let onMouseDownMouseX = 0;
  let onMouseDownMouseY = 0;
  let lon = 0;
  let onMouseDownLon = 0;
  let lat = 0;
  let onMouseDownLat = 0;
  let phi = 0;
  let theta = 0;
  let previousRotation = 0;
  const posObjects = {
    posFrustum: null,
    posMatrix: null,
  };

  const AUTO_SPIN_TIMEOUT = 3000;

  Number.prototype.map = function (in_min, in_max, out_min, out_max) {
    return (
      ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  };
  Math.lerp = (x, y, t) => x * (1 - t) + y * t;
  Math.easeInOut = (t, alpha) => t ** alpha / (t ** alpha + (1 - t) ** alpha);

  export default {
    components: {},

    props: {
      scene: {
        type: Object,
        required: true,
      },
      dialogs: {
        type: Array,
        default: () => [],
      },
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      swipeGif: {
        type: String,
        required: true,
      },
      hotspotsVisible: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      hotspotsClickable: {
        type: Boolean,
        required: false,
        default: () => false,
      },
    },

    data: () => ({
      id: uuidv4(),
      dialog: false,
      isOpeningDialog: false,
      hotspotImg: hotspotImg,
      hotspotImgHover: hotspotImgHover,
      arrowImg: arrowImg,
      arrowImgHover: arrowImgHover,
      isAnimateEnabled: false,
      dialogHotspots: [],
      hubHotspots: [],
      sceneHotspots: [],
      isMouseDown: false,
      isUserInteracting: false,
      isIntroSpinning: true,
      isAutoSpinning: false,
      idleSpinningTimeout: -1,
      idleSpinningInterval: -1,
      forceUpdate: false,
      inRangeSpots: [],
      isSceneCTAVisible: false,
    }),

    computed: {
      sceneContainer() {
        return document.getElementById(this.id);
      },
    },

    methods: {
      async openDialog(dialogName) {
        if (!this.isUserInteracting && !this.isOpeningDialog) {
          if (
            (!this.hotspotsClickable &&
              dialogName === `debut-longue-aventure`) ||
            this.hotspotsClickable
          ) {
            this.isOpeningDialog = true;
            // this.dialog = true
            this.$emit(`open-dialog`, dialogName);
            await this.wait();
            clearTimeout(this.idleSpinningTimeout);
            this.isOpeningDialog = false;
          }
        }
      },
      addHotspotsLocally() {
        let hasDialogHotspotsLocally = false;
        let hasHubHotspotsLocally = false;
        let hasSceneHotspotsLocally = false;
        this.scene.dialogHotspots.forEach((hotspot, i) => {
          if (i === 0) {
            hasDialogHotspotsLocally =
              this.dialogHotspots.findIndex(
                (localHotspot) => localHotspot.uuid === hotspot.uuid
              ) !== -1;
          }
          if (!hasDialogHotspotsLocally) {
            this.dialogHotspots.push(hotspot);
          }
        });
        this.scene.hubHotspots.forEach((hotspot, i) => {
          if (i === 0) {
            hasHubHotspotsLocally =
              this.hubHotspots.findIndex(
                (localHotspot) => localHotspot.uuid === hotspot.uuid
              ) !== -1;
          }
          if (!hasHubHotspotsLocally) {
            this.hubHotspots.push(hotspot);
          }
        });
        this.scene.sceneHotspots.forEach((hotspot, i) => {
          if (i === 0) {
            hasSceneHotspotsLocally =
              this.sceneHotspots.findIndex(
                (localHotspot) => localHotspot.uuid === hotspot.uuid
              ) !== -1;
          }
          if (!hasSceneHotspotsLocally) {
            this.sceneHotspots.push(hotspot);
          }
        });
      },
      goToScene(sceneName) {
        if (this.hotspotsClickable) {
          this.$emit(`change-scene`, sceneName);
        }
      },
      async mountScene() {
        await this.init();
        this.isAnimateEnabled = true;
        this.animate({ firstTime: true });
        setTimeout(() => {
          this.isAnimateEnabled = false;
        }, 500);
        this.$emit(`scene-mounted`);
      },
      async wait(ms) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(), ms);
        });
      },
      async init() {
        camera = new THREE.PerspectiveCamera(
          90,
          window.innerWidth / window.innerHeight,
          1,
          1100
        );
        camera.target = new THREE.Vector3(0, 0, 0);

        threeScene = new THREE.Scene();

        previousRotation = this.scene.rotation;
        geometry = new THREE.SphereGeometry(2, 20, 18, this.scene.rotation);
        geometry.scale(-1, 1, 1);

        let texture;

        if (this.scene.vid) {
          const texSrc = document.getElementById(`sceneTex`);
          texSrc.play();
          texture = new THREE.VideoTexture(texSrc);
        } else {
          texture = await loadTexture(this.scene.image);
        }

        material = new THREE.MeshBasicMaterial({
          map: texture,
        });

        mesh = new THREE.Mesh(geometry, material);

        threeScene.add(mesh);

        this.disposeMaterial();

        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        this.sceneContainer.appendChild(renderer.domElement);

        this.sceneContainer.addEventListener(
          "mousedown",
          this.onDocumentMouseDown,
          false
        );
        this.sceneContainer.addEventListener(
          "mousemove",
          this.onDocumentMouseMove,
          false
        );
        this.sceneContainer.addEventListener(
          "mouseup",
          this.onDocumentMouseUp,
          false
        );
        this.sceneContainer.addEventListener(
          "touchstart",
          this.onDocumentMouseDown,
          { useCapture: false, passive: false }
        );
        this.sceneContainer.addEventListener(
          "touchmove",
          this.onDocumentMouseMove,
          { useCapture: false, passive: false }
        );
        this.sceneContainer.addEventListener(
          "touchend",
          this.onDocumentMouseUp,
          { useCapture: false, passive: false }
        );

        await this.wait();
      },
      async updateScene() {
        await this.wait(150);

        let texture;

        if (this.scene.vid) {
          const texSrc = document.getElementById(`sceneTex`);
          texSrc.play();
          texture = new THREE.VideoTexture(texSrc); //await loadTexture(this.scene.image);
        } else {
          texture = await loadTexture(this.scene.image);
        }

        lon = 0;
        onMouseDownLon = 0;
        lat = 0;
        onMouseDownLat = 0;
        phi = 0;
        theta = 0;

        geometry.rotateY(previousRotation - this.scene.rotation);
        previousRotation = this.scene.rotation;
        mesh.material.map = texture;
        mesh.material.needsUpdate = true;
        this.forceUpdate = true;
        setTimeout(() => {
          this.forceUpdate = false;
        }, 30);
        setTimeout(() => {
          this.enableAnimate();
          this.$emit(`scene-mounted`);
          this.disableAnimate();
        });
      },
      enableAnimate() {
        this.isAnimateEnabled = false;
        this.isAnimateEnabled = true;
      },
      disableAnimate() {
        setTimeout(() => {
          this.isAnimateEnabled = false;
        }, 100);
      },
      animate(
        { firstTime = false, i = 0, maxI = 1 } = {
          firstTime: false,
          i: 0,
          maxI: 1,
        }
      ) {
        renderer.render(threeScene, camera);
        if (
          !this.isSceneCTAVisible &&
          this.hotspotsClickable &&
          this.inRangeSpots.length > 0 &&
          !this.isAutoSpinning
        ) {
          this.isSceneCTAVisible = true;
          this.$emit(`display-scene-cta`);
        } else if (this.isSceneCTAVisible && this.inRangeSpots.length === 0) {
          this.isSceneCTAVisible = false;
          this.$emit(`hide-scene-cta`);
        }
        setTimeout(
          requestAnimationFrame(() =>
            this.animate({
              i: newI,
              maxI: $maxI,
            })
          ),
          1000 / 16
        );
        if (!this.isAnimateEnabled && !this.isAutoSpinning) {
          return;
        }
        const $maxI = firstTime ? 10 : maxI;
        const force = i < $maxI;
        const newI = i + 1;
        this.update(force);
        if (
          this.isAutoSpinning ||
          this.isUserInteracting ||
          force ||
          this.forceUpdate
        ) {
          this.posHotspots();
        }
      },
      update(force = false) {
        if (!camera) {
          return;
        }

        if (
          this.isAutoSpinning ||
          this.isUserInteracting ||
          force ||
          this.forceUpdate
        ) {
          lat = Math.max(-85, Math.min(85, lat));
          phi = THREE.Math.degToRad(90 - lat);
          theta = THREE.Math.degToRad(lon);

          camera.target.x = 2 * Math.sin(phi) * Math.cos(theta);
          camera.target.y = 2 * Math.cos(phi);
          camera.target.z = 2 * Math.sin(phi) * Math.sin(theta);

          // const target = camera.target;
          // console.log(target);

          camera.lookAt(camera.target);
        }
      },
      posHotspot(hotspot, type) {
        if (hotspot.sceneName !== this.scene.name) {
          return;
        }
        // console.log(hotspot);

        const hotspotZ = hotspot.z || -10;
        const posVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
        const posViewVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
        // posObjects.posVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
        // posObjects.posViewVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
        const canvas = renderer.domElement;

        posVector.project(camera);

        posVector.x = Math.round(
          (0.5 + posVector.x / 2) * (canvas.width / window.devicePixelRatio)
        );
        posVector.y = Math.round(
          (0.5 - posVector.y / 2) * (canvas.height / window.devicePixelRatio)
        );
        posVector.z = hotspotZ;

        let xPx = `${posVector.x}px`;
        let yPx = `${posVector.y}px`;

        if (!posObjects.posFrustum) {
          posObjects.posFrustum = new THREE.Frustum();
          posObjects.posMatrix = new THREE.Matrix4().multiplyMatrices(
            camera.projectionMatrix,
            camera.matrixWorldInverse
          );
          posObjects.posFrustum.setFromProjectionMatrix(posObjects.posMatrix);
        }
        const isVisible = posObjects.posFrustum.containsPoint(posViewVector);

        if (!isVisible) {
          xPx = `-100vw`;
          yPx = `-100vw`;
        }

        if (this.$refs[hotspot.uuid] && this.$refs[hotspot.uuid][0]) {
          this.$refs[hotspot.uuid][0].style.left = xPx;
        }
        if (
          !this.inRangeSpots.includes(hotspot.uuid) &&
          type === `scene` &&
          isVisible &&
          posVector.x >= (canvas.width / window.devicePixelRatio) * 0.4 &&
          posVector.x <= (canvas.width / window.devicePixelRatio) * 0.6
        ) {
          this.inRangeSpots.push(hotspot.uuid);
        } else if (
          this.inRangeSpots.includes(hotspot.uuid) &&
          (type !== `scene` ||
            !isVisible ||
            posVector.x < (canvas.width / window.devicePixelRatio) * 0.4 ||
            posVector.x > (canvas.width / window.devicePixelRatio) * 0.6)
        ) {
          const idx = this.inRangeSpots.indexOf(hotspot.uuid);
          this.inRangeSpots.splice(idx, 1);
        }
        if (this.$refs[hotspot.uuid] && this.$refs[hotspot.uuid][0]) {
          this.$refs[hotspot.uuid][0].style.top = yPx;
        }
      },
      posHotspots() {
        posObjects.posFrustum = null;
        posObjects.posMatrix = null;
        this.dialogHotspots.forEach((hotspot) => {
          this.posHotspot(hotspot, `dialog`);
        });
        this.hubHotspots.forEach((hotspot) => {
          this.posHotspot(hotspot, `hub`);
        });
        this.sceneHotspots.forEach((hotspot) => {
          this.posHotspot(hotspot, `scene`);
        });
        posObjects.posFrustum = null;
        posObjects.posMatrix = null;
      },
      onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

        this.posHotspots();
      },
      getEventCoordinates(event) {
        let [x, y] = [null, null];

        if (
          event.type == "touchstart" ||
          event.type == "touchmove" ||
          event.type == "touchend" ||
          event.type == "touchcancel"
        ) {
          const touchEvent =
            typeof event.originalEvent === "undefined"
              ? event
              : event.originalEvent;
          const touch = touchEvent.touches[0] || touchEvent.changedTouches[0];
          x = touch.pageX || null;
          y = touch.pageY || null;
        } else if (
          event.type == "mousedown" ||
          event.type == "mouseup" ||
          event.type == "mousemove" ||
          event.type == "mouseover" ||
          event.type == "mouseout" ||
          event.type == "mouseenter" ||
          event.type == "mouseleave"
        ) {
          x = event.clientX || null;
          y = event.clientY || null;
        }

        return { x, y };
      },
      onDocumentMouseDown(event) {
        this.enableAnimate();
        event.preventDefault();
        this.isMouseDown = true;

        const { x, y } = this.getEventCoordinates(event);

        onMouseDownMouseX = x;
        onMouseDownMouseY = y;
        onMouseDownLon = lon;
        onMouseDownLat = lat;

        this.$emit(`user-interacted`);
        this.isAutoSpinning = false;
        if (this.isIntroSpinning) {
          this.isIntroSpinning = false;
        }
        clearTimeout(this.idleSpinningTimeout);
      },
      onDocumentMouseMove(event) {
        if (!this.isMouseDown) {
          return;
        }
        const { x, y } = this.getEventCoordinates(event);
        if (draggingXOrigin === null || draggingYOrigin === null) {
          draggingXOrigin = x;
          draggingYOrigin = y;
        } else {
          // const dragXDistance = Math.abs(x - draggingXOrigin);
          // const dragYDistance = Math.abs(y - draggingYOrigin);
          // this.isUserInteracting = dragXDistance >= 20 || dragYDistance >= 20;
          this.isUserInteracting = true;
        }

        if (this.isUserInteracting === true) {
          const newLat = (y - onMouseDownMouseY) * 0.1 + onMouseDownLat;
          lon = (onMouseDownMouseX - x) * 0.1 + onMouseDownLon;
          if (Math.abs(newLat) <= 30) {
            lat = newLat;
          } else {
            onMouseDownMouseY = y;
            onMouseDownLat = lat;
          }
        }

        this.$emit(`user-interacted`);
        this.isAutoSpinning = false;
        if (this.isIntroSpinning) {
          this.isIntroSpinning = false;
        }
        clearTimeout(this.idleSpinningTimeout);
      },
      onDocumentMouseUp() {
        this.isMouseDown = false;
        draggingXOrigin = null;
        draggingYOrigin = null;
        setTimeout(() => {
          this.isUserInteracting = false;
        });
        this.startIdleSpinTimeout();
        // this.idleSpinningTimeout = setTimeout(() => { this.isAutoSpinning = true; }, AUTO_SPIN_TIMEOUT);
        this.disableAnimate();

        this.$emit(`user-interacted`);
      },
      startIdleSpinTimeout() {
        this.idleSpinningTimeout = setTimeout(() => {
          this.isAutoSpinning = true;
        }, AUTO_SPIN_TIMEOUT);
      },
      removeListeners() {
        this.sceneContainer.removeEventListener(
          "mousedown",
          this.onDocumentMouseDown,
          false
        );
        this.sceneContainer.removeEventListener(
          "mousemove",
          this.onDocumentMouseMove,
          false
        );
        this.sceneContainer.removeEventListener(
          "mouseup",
          this.onDocumentMouseUp,
          false
        );
        this.sceneContainer.removeEventListener(
          "touchstart",
          this.onDocumentMouseDown,
          { useCapture: false, passive: false }
        );
        this.sceneContainer.removeEventListener(
          "touchmove",
          this.onDocumentMouseMove,
          { useCapture: false, passive: false }
        );
        this.sceneContainer.removeEventListener(
          "touchend",
          this.onDocumentMouseUp,
          { useCapture: false, passive: false }
        );

        // window.removeEventListener( 'resize', this.onWindowResize.bind(this), false );
      },
      disposeGeometry() {
        for (const key in mesh.geometry.attributes) {
          mesh.geometry.deleteAttribute(key);
        }
        mesh.geometry.setIndex([]);
        mesh.geometry.dispose();
      },
      disposeMaterial() {
        mesh.material.dispose();
      },
      disposeObjects() {
        this.disposeGeometry();
        this.disposeMaterial();
        threeScene.remove(mesh);
        geometry.dispose();
      },
    },
    async mounted() {
      // if (this.mounted) {
      //   return this.mountScene();
      // }
      await this.mountScene();
      this.addHotspotsLocally();
      this.posHotspots();
      this.$root.$on(`dialog-closed`, this.startIdleSpinTimeout);
      this.$root.$on(`courtin-popup-opened`, () => {
        this.isAutoSpinning = false;
        if (this.isIntroSpinning) {
          this.isIntroSpinning = false;
        }
        clearTimeout(this.idleSpinningTimeout);
      });
    },

    beforeDestroy() {
      this.disableAnimate();
      this.removeListeners();
      this.disposeObjects();
    },

    watch: {
      visible(visible) {
        if (!visible) {
          this.isAutoSpinning = false;
          clearTimeout(this.idleSpinningTimeout);
          clearInterval(this.idleSpinningInterval);
          this.inRangeSpots = [];
          setTimeout(() => {
            lon = 0;
            onMouseDownLon = 0;
            lat = 0;
            onMouseDownLat = 0;
            phi = 0;
            theta = 0;
          }, 500);
        }
      },
      scene() {
        this.inRangeSpots = [];
        this.updateScene();
        this.addHotspotsLocally();
        this.posHotspots();
        setTimeout(() => {
          this.posHotspots();
        }, "500");
        setTimeout(() => {
          geometry.rotateY(0.1);
          this.posHotspots();
          geometry.rotateY(-0.1);
          this.startIdleSpinTimeout();
        }, "1500");
      },
      hotspotsVisible(visible) {
        if (visible) {
          this.isIntroSpinning = true;
          this.isAutoSpinning = true;
        }
      },
      isIntroSpinning(spinning) {
        if (!spinning) {
          this.$emit(`intro-finished`);
        }
      },
      isAutoSpinning(spinning) {
        if (spinning) {
          const initialLat = lat;
          let i = 0;
          const lerpIterations = 200;
          this.idleSpinningInterval = setInterval(() => {
            lon += 0.05;
            if (lon >= 360) {
              if (this.isIntroSpinning) {
                this.isIntroSpinning = false;
                this.isAutoSpinning = false;
              }
              lon = 0;
            }
            if (i < lerpIterations) {
              let interpolation = Math.easeInOut(i / lerpIterations, 2);
              lat = interpolation.map(0, 1, initialLat, 0);
              i += 1;
            }
          }, 10);
        } else {
          clearInterval(this.idleSpinningInterval);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
  }

  @mixin keyframes($name) {
    @keyframes #{$name} {
      @content;
    }
  }

  .swipe-cta {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: rgba(0, 0, 0, 0);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;

    img {
      width: 20%;
      filter: drop-shadow(2px 4px 6px #98989891);
    }

    &.visible {
      opacity: 1;
    }
  }

  .clarins-scene {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }

  .hub-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #b4001a;
    color: #fff;
    font-size: 1.7vw;
    border-radius: 4.6vw;
    white-space: nowrap;
    border: 0.9vw solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 1.8vw;
  }

  .dialog-hotspot,
  .scene-hotspot {
    position: absolute;
    width: 4.8vw;
    height: 4.8vw;
    top: -10vw;
    left: -10vw;
    margin-top: -2.4vw;
    margin-left: -2.4vw;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .scene-hotspot-legend,
    .dialog-hotspot-legend {
      position: absolute;
      top: 5.5vw;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      font-size: 1.7vw;
      border-radius: 3px;
      white-space: nowrap;
      border: 0.9vw solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      padding: 0.9vw;
    }

    &.courtin {
      width: 7.6vw;
      height: 7.6vw;
      margin-top: -3.8vw;
      margin-left: -3.8vw;

      .dialog-hotspot-legend {
        top: 9.2vw;
      }
    }
  }

  .dialog-hotspot {
    border-radius: 2.4vw;

    &.courtin {
      border-radius: 3.8vw;
    }

    &.disabled {
      pointer-events: none;
      img {
        filter: grayscale(1) brightness(2);
      }
      .dialog-hotspot-legend {
        filter: grayscale(1) brightness(0.7);
      }
    }

    &:not(.disabled) {
      @include keyframes(pulse) {
        from {
          box-shadow: 0 0 0 0vw rgb(180, 0, 26);
        }
        to {
          box-shadow: 0 0 0 2.8vw rgba(0, 0, 0, 0);
        }
      }
      @include animate(pulse, 1.5s, ease, infinite);
    }
  }

  .scene-hotspot {
    &.disabled {
      filter: grayscale(1) brightness(0.7);
      pointer-events: none;
    }

    &:not(.disabled) img {
      border-radius: 2.4vw;
    }
  }

  .dialog-hotspot img,
  .scene-hotspot img {
    width: 100%;
    height: 100%;
  }
</style>
